import * as React from 'react'
import { Container } from "@mui/material"
import { Fade } from "react-reveal"

export const Account = (props) => {
    return (
        <Container>
            <div className='pageHeight'>
                <Fade>
                    <h1>App Account</h1>
                </Fade>
            </div>
        </Container>
    )
}
