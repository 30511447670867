import * as React from 'react'
import { Container } from "@mui/material"
import { LoginForm } from '../../components/cognitoauth/views/LoginForm'
import { AuthIsSignedIn } from '../../components/cognitoauth/auth/Auth'
import { Navigate } from 'react-router'

export const Login = (props) => {

    return (
        <Container>
            <div className='pageHeight'>
                <LoginForm />
            </div>

            <AuthIsSignedIn>
                <Navigate to='/data/explore' />
            </AuthIsSignedIn>
        </Container>
    )
}