import * as React from 'react'
import { Button, TextField, Alert, Grid } from "@mui/material"
import { AuthContext } from '../auth/Auth';
import { Navigate } from 'react-router';

export const RegisterForm = (props) => {
    const [ username, setUsername ] = React.useState('')
    const [ email, setEmail ] = React.useState('')
    
    const [ password, setPassword ] = React.useState('')

    const [ code, setCode] = React.useState('')
    const [ redirect, setRedirect] = React.useState(false)

    const [ registerText, setRegisterText] = React.useState("Register")
    const [ errorMessage, setErrorMessage] = React.useState(null)
    const [ successMessage, setSuccessMessage] = React.useState(null)

    const [ codeSent, setCodeSent] = React.useState(false)



    const changeCode = (e) => setCode(e.target.value)
    const changeEmail = (e) => setEmail(e.target.value)
    const changeUsername = (e) => setUsername(e.target.value)

    const changePassword = (e) => {setPassword(e.target.value) }

    // Confirm Password
    const [ confirmPassword, setConfirmPassword ] = React.useState('')
    const [ passwordMatch, setPasswordMatch] = React.useState(false)
    const changeConfirm = (e) => { setConfirmPassword(e.target.value) }
    React.useEffect(() => {
        const checkMatch = () => {
            if (confirmPassword === password && (password !== '')) {
                setPasswordMatch(true)
            } else {
                setPasswordMatch(false)
            }
        }
        checkMatch()
    }, [confirmPassword, password])

    const authContext = React.useContext(AuthContext) 

    const register = () => {  
        const RegisterFunc = async () => {
            try {
                await authContext.signUpWithEmail(username, email, password)
                // True show email code box 
                setCodeSent(true)
                setRegisterText("Confirm Email Code")

                setErrorMessage(null)
                setSuccessMessage("Verification code sent to email")

            } catch (err) {
                // False
                console.log(err)
                setErrorMessage(`${err}`)
                setSuccessMessage(null)
            }
        }

        const VerifyCode = async () => {
            // Verify Code Here
            try {
                await authContext.verifyCode(username, code)

                // Verified Here Redirect
                setRedirect(true)
            } catch (err) {
                console.log(err)
            }            
        }

        if ((code !== '') && (passwordMatch)) {
            VerifyCode()
        } else if (passwordMatch) {
            // Send Code Here
            RegisterFunc()
        }
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6} style={{flexDirection: 'column', display: 'flex'}}>
                    <h1>Register</h1>
                    <TextField className="mb-10" id="username" value={username} label="Username" variant="outlined" onChange={changeUsername} />
                    <TextField className="mb-10" id="email" value={email} label="Email" variant="outlined" onChange={changeEmail} />

                    <div style={{paddingBottom: '20px'}}>
                        <div>
                            <TextField className="mb-10" style={{display: 'flex'}} id="password" type="password" value={password} label="Password" variant="outlined" onChange={changePassword} />
                        </div>
                        <div>
                            <TextField className="mb-10" style={{display: 'flex'}} id="confirmpassword" type="password" value={confirmPassword} label="Confirm Password" variant="outlined" onChange={changeConfirm} />
                        </div>
                        <div>{ passwordMatch === true ? <></> : <Alert severity="error">Passwords Do Not Match</Alert> }</div>
                    </div>

                    { codeSent === true ? (
                        <TextField className="mb-10" id="emailcode" value={code} label="Code" variant="outlined" onChange={changeCode} />
                    ) : null}

                    <Button onClick={register}  className="mb-10" variant="contained">{registerText}</Button>
                    <div style={{margin: '20px'}}>
                        { errorMessage !== null ? (
                            <Alert severity="error">{ errorMessage }</Alert>
                        ) : null }

                        { successMessage !== null ? (
                            <Alert severity="success">{ successMessage }</Alert>
                        ) : null }
                    </div>
                </Grid>
                <Grid item xs={0} md={3} />
            </Grid>

            { redirect === true ? (
                <Navigate to='/' />
            ) : null}

        </div>
    )
}
