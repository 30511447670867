import * as React from 'react'
import {
    DataGridPremium,
  } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import { APIContext } from '../../../components/api/Api';
import { Modal, Typography, Box, Button } from '@mui/material'
import {Link} from "react-router-dom";


LicenseInfo.setLicenseKey('91ead7de2936caf6cbb60f548b00ac4aTz00ODIyNixFPTE2OTA3Mzg5OTE5MDMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


export const CompanyListEdit = (props) => {
    const [ dataLoaded, setData ] = React.useState([])

    console.log("dataLoaded", dataLoaded);
    const initialCols = [
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'sector', headerName: 'Sector', width: 200 },
        { field: 'country', headerName: 'Country', width: 80 },
        { field: 'visible', headerName: 'Visible', width: 80},
        { field: 'last-snapshot', headerName: 'Last Snapshot', width: 150 },
        { field: 'data-assessments', headerName: 'Assessments', width: 150, editable: true  },
        { field: 'data-bloomberg', headerName: 'Bloomberg', width: 150, editable: true  },
        { field: 'data-glassdoor', headerName: 'Glassdoor', width: 150, editable: true  },
        {
            field: 'action',
            type: 'actions',
            headerName: '',
            width: 150,
            getActions: (params) => [
                <div>
                    <Link to={process.env.PUBLIC_URL + `/admin/company/${params.id}`} className="destyle" >
                        <div data-comp={params.id}>Edit</div>
                    </Link>
                </div>
            ]
        }
    ]


    const ApiC = React.useContext(APIContext)

    // Onload get admin api
    React.useEffect(() => {
        ApiC.AdminCompanyList().then((res) => {
            setData(FormatAdminResp(res))
        })
    }, [])

    return (
        <Box sx={{ height: 1000, width: '100%' }}>
            <DataGridPremium
                columns={initialCols}
                rows={dataLoaded}
                >
            </DataGridPremium>
        </Box>
    )
}

const EditModal = ({data}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };


    return (
      <div>
        <Button onClick={handleOpen} variant="contained">Edit</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit
            </Typography>
            <EditForm data={data} handleClose={handleClose} />
          </Box>

        </Modal>
      </div>
    );
}

const FormatAdminResp = (data) => {
    let finalArr = data.map((item, i) => {
        return {
            id: i, ...item,
            actions: {},

        }
    })

    return finalArr
}

// Edit form within modal
const EditForm = ({data, handleClose}) => {

    const initialCols = [
        { field: 'name', headerName: 'Name', width: 300, editable: true },
        { field: 'sector', headerName: 'Sector', width: 200, editable: true },
        { field: 'country', headerName: 'Country', width: 80, editable: true },
        { field: 'last-snapshot', headerName: 'Last Snapshot', width: 150, editable: true },
        { field: 'data-assessments', headerName: 'Assessments', width: 150, editable: true  },
        { field: 'data-bloomberg', headerName: 'Bloomberg', width: 150, editable: true  },
        { field: 'data-glassdoor', headerName: 'Glassdoor', width: 150, editable: true  },

    ]

    console.log(data.row)

    return (
        <Box sx={{width: '100%', minHeight: '180px'}}>
            <p>
                Double Click the cell to edit item.
            </p>
            <DataGridPremium
                columns={initialCols}
                rows={[data.row]}
                style={{minHeight: '180px'}}
                >
            </DataGridPremium>

            <div style={{padding: '10px', display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="contained" onClick={handleClose} >Save</Button>
            </div>
        </Box>
    )
}
