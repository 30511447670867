import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { AuthIsNotSignedIn, AuthIsSignedIn, AuthIsSuper, IsNotGuest } from './cognitoauth/auth/Auth';
import { IsGuest } from './cognitoauth/auth/Auth'
import { adminUrl } from './cognitoauth/config/adminUrl'

class Page {
    constructor(name, link, guest) {
        this.name = name;
        this.link = link;
        this.guest = guest;
    }
}

/* Dynamic Pages Based on Roles */
const signedInPages = [
  new Page('Cohorts', '/cohorts'),
  new Page('Overview', '/data/explore', true),
  // new Page('Vertical', '/data/vertical'),
  new Page('Breakdown', '/data/exploratory'),
  //new Page('Detailed', '/data/company/1'),
  new Page('Profile', '/profile', true),
  new Page('Logout', '/logout', true)
];

const demoPages = [
    new Page('Overview', '/data/explore', true),
    // new Page('Vertical', '/data/vertical'),
    new Page('Breakdown', '/data/exploratory'),
    //new Page('Detailed', '/data/company/1'),
    new Page('Profile', '/profile', true),
    new Page('Logout', '/logout', true)
];

const signedOutPages = [
  new Page('Login', '/', true),
  new Page('Register', '/auth/register', true),
]

const superAdminPages = [
  new Page('Admin', adminUrl)
]

export const Navbar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };



  return (
    <AppBar position="sticky" className="navbar">
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
           { /* Icon Here */ }
           <Link to={'/'} className="destyle logo">
              <img src={process.env.PUBLIC_URL + '/img/logo/logo-text-white.png'} alt="logo" />
           </Link>


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <AuthIsNotSignedIn>
                  {signedOutPages.map((page) => (
                        <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                            <MenuItem >
                              <Typography textAlign="center">{page.name}</Typography>
                            </MenuItem>
                        </Link>
                      )
                  )}
                </AuthIsNotSignedIn>

                <AuthIsSignedIn>
                <IsNotGuest>
                  {signedInPages.map((page) => (
                      <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                          <MenuItem >
                          <Typography textAlign="center">{page.name}</Typography>
                          </MenuItem>
                      </Link>
                    )
                  )}
                </IsNotGuest>
                </AuthIsSignedIn>

                <AuthIsSuper>
                <IsNotGuest>
                { superAdminPages.map((page) => (
                      <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                        <MenuItem >
                          <Typography textAlign="center">{page.name}</Typography>
                        </MenuItem>
                      </Link>

                  )
                )}
                </IsNotGuest>
                </AuthIsSuper>

                { /* GUEST ROUTES */ }
                <IsGuest>
                      {signedInPages.map((page) => {
                          if (page.guest === true) {
                            return (
                              <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                                  <MenuItem >
                                  <Typography textAlign="center">{page.name}</Typography>
                                  </MenuItem>
                              </Link>
                            )
                          }
                          return null
                        }
                      )}
                </IsGuest>





            </Menu>
          </Box>
          { /* Icon Here */ }
          <Typography
            className='uppercase'
            variant="h5"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          ><Link to={'/'} className="destyle">
            SocialMarks
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end'}}>

            <IsNotGuest>
              <AuthIsSignedIn>
                  <AuthIsSuper>
                      { superAdminPages.map((page) => {
                          return (
                              // <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                              //     <Button sx={{ my: 2, color: 'white', display: 'block'}} >
                              //         {page.name}
                              //     </Button>
                              // </Link>
                              <a href={page.link} target="_blank" className="destyle">
                                  <Button sx={{ my: 2, color: 'white', display: 'block'}} >{page.name}</Button>
                              </a>
                          )
                      })}
                  </AuthIsSuper>
              </AuthIsSignedIn>


              <AuthIsNotSignedIn>
                {signedOutPages.map((page) => {
                    return (
                      <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                          <Button sx={{ my: 2, color: 'white', display: 'block'}}>
                              {page.name}
                          </Button>
                      </Link>
                    )
                })}
              </AuthIsNotSignedIn>

              <AuthIsSignedIn>
                {signedInPages.map((page) => {
                    return (
                      <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                          <Button sx={{ my: 2, color: 'white', display: 'block'}} >
                              {page.name}
                          </Button>
                      </Link>
                    )
                })}
              </AuthIsSignedIn>
            </IsNotGuest>

            { /* Guest Routes */ }

            <IsGuest>
              <AuthIsNotSignedIn>
                {signedOutPages.map((page) => {
                  if (page.guest === true) {
                    return (
                      <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                          <Button sx={{ my: 2, color: 'white', display: 'block'}}>
                              {page.name}
                          </Button>
                      </Link>
                    )
                  }
                  return null
                })}
              </AuthIsNotSignedIn>

              <AuthIsSignedIn>
                {signedInPages.map((page) => {
                  if (page.guest === true) {
                    return (
                      <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                          <Button sx={{ my: 2, color: 'white', display: 'block'}} >
                              {page.name}
                          </Button>
                      </Link>
                    )
                  }
                  return null
                })}
              </AuthIsSignedIn>
            </IsGuest>




          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
