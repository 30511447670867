import * as React from 'react'
import { Grid, TextField, Button, Alert } from "@mui/material"

import { AuthContext } from '../auth/Auth';

export const ForgotPasswordForm = (props) => {
    const [ username, setUsername] = React.useState('')
    const [ password, setPassword] = React.useState('')
    const [ code, setCode] = React.useState('')

    const [ showChangePw, setShowChangePw] = React.useState(false)

    const [ errorMessage, setErrorMessage] = React.useState(null)
    const [ successMessage, setSuccessMessage] = React.useState(null)

    const changeUsername = (e) => setUsername(e.target.value)
    const changePassword = (e) => setPassword(e.target.value)
    const changeCode = (e) => setCode(e.target.value)

    const authContext = React.useContext(AuthContext) 

    const [ confirmPassword, setConfirmPassword ] = React.useState('')
    const [ passwordMatch, setPasswordMatch] = React.useState(false)
    const changeConfirm = (e) => { setConfirmPassword(e.target.value) }
    React.useEffect(() => {
        const checkMatch = () => {
            if (confirmPassword === password && (password !== '')) {
                setPasswordMatch(true)
            } else {
                setPasswordMatch(false)
            }
        }
        checkMatch()
    }, [confirmPassword, password])

    const send = () => {
        const SendCode = async () => {
            try {
                await authContext.sendCode(username)
                // True show email code box 
                setShowChangePw(true)
            } catch (err) {
                // False
                setErrorMessage(`${err}`)
                setSuccessMessage(null)
            }
        }
        SendCode()
    }

    const changePw = () => {
        const changePassword = async () => {
            try {
                
                // True show email code box 
                
                await authContext.forgotPassword(username, code, password)
                setSuccessMessage(`Successfully change password`)
                setErrorMessage(null)
            } catch (err) {
                // False
                setErrorMessage(`${err}`)
                setSuccessMessage(null)
            }
        }

        if (passwordMatch) {
            changePassword()
        }

        // Verify code

        // set password
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6} style={{flexDirection: 'column', display: 'flex'}}>

                    <h1>Forgot Password</h1>
                    <TextField className="mb-10" id="username" value={username} label="Username" variant="outlined" onChange={changeUsername} />
                    <Button variant="contained" className="mb-10" onClick={send}>Send verification code</Button>
                    <hr />

                    { showChangePw === true ? (
                        <>
                        <TextField className="mb-10" id="code" value={code} label="Code" variant="outlined" onChange={changeCode} />
                        
                        <div style={{paddingBottom: '20px'}}>
                            <div>
                                <TextField className="mb-10" style={{display: 'flex'}} id="password" type="password" value={password} label="New Password" variant="outlined" onChange={changePassword} />
                            </div>
                            <div>
                                <TextField className="mb-10" style={{display: 'flex'}} id="confirmpassword" type="password" value={confirmPassword} label="Confirm Password" variant="outlined" onChange={changeConfirm} />
                            </div>
                            <div>{ passwordMatch === true ? <></> : <Alert severity="error">Passwords Do Not Match</Alert> }</div>
                        </div>
                        
                        <Button variant="contained" onClick={changePw}>Change Password</Button>
                        </>
                    ) : null}

                    <div style={{margin: '20px'}}>
                        { errorMessage !== null ? (
                            <Alert severity="error">{ errorMessage }</Alert>
                        ) : null }

                        { successMessage !== null ? (
                            <Alert severity="success">{ successMessage }</Alert>
                        ) : null }
                    </div>
                </Grid>
                <Grid item xs={0} md={3} /> 
            </Grid>


        </div>
    )
}
