import * as React from 'react'
import axios from 'axios';
import {BaseApiUrl}  from './BaseUrl'
import { useCallback, useMemo } from 'react';
import { StatusContext } from '../contexts/Status';

export const APIContext = React.createContext()



// API Context Provider
export const APIProvider = ({ children }) => {
    // Basic token / administrator / authentication
    const [ token, setToken ] = React.useState('')
    const [ accessToken, setAccess] = React.useState(window.localStorage.getItem("idToken"))
    const [ authenticated, setAuth ] = React.useState(false)
    const [ administrator, setAdmin ] = React.useState(false)



    const changeAccessS = (tokenStr) => { setAccess(tokenStr) }
    const changeTokenS = (tokenStr) => { setToken(tokenStr) }
    const changeAuthS = (auth) => { setAuth(auth) }
    const changeAdminS = (admin) => { setAdmin(admin) }




    const StatusC = React.useContext(StatusContext)

    /* API Functions Below */
    const headersToUse = useMemo(() => {
        return {headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'AccessToken': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': true,
        }}
    }, [token, accessToken])

    const PostHeadersToUse = useMemo(() => {
        return {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
                'AccessToken': `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': true,
            }
        }
    }, [token, accessToken])

    /* Helpers */
    /*
    const ConvertToArrayIfNeccessary = (obj) => {
        if (typeof(obj === "object")) {
            return Object.values(obj)
        }
        return obj
    }
    */



    /* ACTIVELY IMPLEMENTED */
    /* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

    const UpdateCompanyField = useCallback(async (es_key, company_id, data) => {
        let postData = {es_key: es_key, company_id: company_id, data: data}
        const res = await axios.post(BaseApiUrl + `/api/v1/admin/company/all-data-update`, postData, PostHeadersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [PostHeadersToUse])


    const GetAdminCompanyMetaById = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/admin/company/view/${id}`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [headersToUse])

    const GetAdminExploratoryByCompanyID = useCallback(async (id) => {
            const res = await axios.get(BaseApiUrl + `/api/v1/admin/company/exploratory/${id}`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
            return res["data"]
        }, [headersToUse])

    const GetRealizedCompanyById = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/admin/company/all-data/${id}`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [headersToUse])

    const GetCompanyList = useCallback(async () => {
        const res = await axios.get(BaseApiUrl + '/api/v1/company/list', headersToUse);
        return res["data"]["data"]
    },[headersToUse])

    const DataCategoryList = useCallback(async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/category/list`, headersToUse);
        let endArray = Object.values(res["data"])
        return endArray
    }, [headersToUse])

    const DataSubcategoryList = useCallback(async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/subcategory/list`, headersToUse);
        return Object.values(res["data"])
    }, [headersToUse])

    const DrillDownDataReport = useCallback(async (subcategory, companyId) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/reports/drill-down/${subcategory}/${companyId}`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [headersToUse])

    const CompanyScoreHistory = useCallback(async (companyId) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/company/score-history/${companyId}`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [headersToUse])

    /* Active cohorts */
    const CreateCohort = useCallback(async (name) => {
        let postData = {name: name, companies: "[]"}
        const res = await axios.post(BaseApiUrl + `/api/v1/cohort/create`, postData, PostHeadersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [PostHeadersToUse])

    const DeleteCohort = useCallback(async (id) => {
        const res = await axios.delete(BaseApiUrl + `/api/v1/cohort/delete/${id}`, headersToUse)
        return res["data"]
    }, [headersToUse])

    const PublicCohorts = useCallback(async() => {
        const res = await axios.get(BaseApiUrl + `/api/v1/public-cohorts`, headersToUse);
        return res["data"]
    }, [headersToUse])

    const UpdateCohort = async (id, list) => {
        StatusC.DisplayMessage("Updating Cohort...", "info", 1000)
        const postData = {cohort_id: id, companies: JSON.stringify(list)}
        const res = await axios.post(BaseApiUrl + `/api/v1/cohort/update`, postData, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }

    const ListCohorts = useCallback(async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/cohorts`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [headersToUse])

    const GetCohortById = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/cohort/${id}`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [headersToUse])

    /* Category Rankings */
    const CategoryRank = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/reports/category-rank?cohort_id=${id}`, headersToUse); // NEED TO ADJUST COMPANY ID FOR THIS CALL ${companyId}
        return res["data"]
    }, [headersToUse])

    const GetAssessmentsByCategory = useCallback(async (category, id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/assessments-by-category/${category}?cohort_id=${id}`, headersToUse);
        return res["data"]
    }, [headersToUse])

    /* All Data for Exploratory */
    const GetAllDataByCohort = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/companies/all-data?cohort_id=${id}`, headersToUse);
        return res["data"]
    }, [headersToUse])

    const GetCompanyDetailById = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/company/detail/${id}`, headersToUse);
        return res["data"]
    }, [headersToUse])

    const GetCompanyMetaById = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/company/meta/${id}`, headersToUse);
        return res["data"]
    }, [headersToUse])

    const GetCompanyDetailByIdGuest = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/company/guest-detail/${id}`, headersToUse);
        return res["data"]
    }, [headersToUse])

    /* Exploratory */
    const ExploratoryByCohortId = useCallback(async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/company/exploratory?cohort_id=${id}`, headersToUse);
        return res["data"]
    }, [headersToUse])

    /* File Upload */
    // type = demodata
    // rerun_duplicate = true
    // csv - filedata
    const AdminUpload = async (type, rerun_duplicate, file) => {
        const formData = new FormData();
        formData.append('type', type)
        formData.append('rerun_duplicate', rerun_duplicate)
        formData.append('file', file)
        const res = await axios.post(BaseApiUrl + `/api/v1/admin/upload`, formData, headersToUse);
        return res["data"]
    }

    /* File List */
    const ListFilesUploaded = async (page, perPage, status) => {
        let urlCraft = ''
        if (status !== undefined) {
            urlCraft = `/api/v1/admin/upload/file-list?page=${page}&per_page=${perPage}&status=${status}`
        } else {
            urlCraft = `/api/v1/admin/upload/file-list?page=${page}&per_page=${perPage}`
        }
        const res = await axios.get(BaseApiUrl + urlCraft, headersToUse);
        return res["data"]
    }


    const AdminCompanyList = async () =>  {
        const res = await axios.get(BaseApiUrl + `/api/v1/admin/company/list`, headersToUse);
        return res["data"]
    }

    // User Information
    /*
    const ListUserGroups = async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/user/groups`, headersToUse);
        return res["data"]
    }

    const UserInformation = async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/user`, headersToUse);
        return res["data"]
    }
    */


    /* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
    /* XXXXXXXXXX    NOT IMPLEMENTED BELOW   XXXXXXXXXXX */
    /* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
    const FetchCompany = async() => {
        const res = await axios.get(BaseApiUrl + '/api/v1/data/model/company', headersToUse);
        console.log('[ FetchCompany Result ]')
        console.log(res)
    }


    const GetCompanyId = async (id) => {
        const res = await axios.get(BaseApiUrl + `/api/v1/company/meta/${id}`, headersToUse);
        console.log('[ GetCompanyId ] Result')
        console.log(res)
    }



    const DataKPIList = async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/kpi/list`, headersToUse);
        console.log('[ DataKPIList ] Result')
        console.log(res)
    }

    const DataScoreTypeList = async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/score-type/list`, headersToUse);
        console.log('[ DataScoreTypeList ] Result')
        console.log(res)
    }

    const DataSectorList = async () => {
        const res = await axios.get(BaseApiUrl + `/api/v1/sector/list`, headersToUse);
        console.log('[ DataSectorList ] Result')
        console.log(res)
    }


    /* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
    /* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

    const defaultState = {
        token,
        changeTokenS,
        authenticated,
        changeAuthS,
        changeAccessS,
        administrator,
        changeAdminS,

        // Exported API Functions Below
        GetCompanyList,
        GetCompanyId,
        GetRealizedCompanyById,
        DrillDownDataReport,
        DataCategoryList,
        DataSubcategoryList,
        DataKPIList,
        DataScoreTypeList,
        DataSectorList,
        GetAdminExploratoryByCompanyID,
        GetAdminCompanyMetaById,
        GetCompanyMetaById,

        // Helpers Across
        GetAssessmentsByCategory,
        FetchCompany,
        CompanyScoreHistory,

        // Cohorts
        CreateCohort,
        DeleteCohort,
        UpdateCohort,
        ListCohorts,
        GetCohortById,

        // Explore
        CategoryRank,

        // Exploratory
        GetAllDataByCohort,
        ExploratoryByCohortId,

        // Deep
        GetCompanyDetailById,

        // File Management
        ListFilesUploaded,
        AdminUpload,

        PublicCohorts,

        // Admin Company List
        AdminCompanyList,
        GetCompanyDetailByIdGuest,
        UpdateCompanyField
    }



    return <APIContext.Provider value={defaultState}>{children}</APIContext.Provider>
}
