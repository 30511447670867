import * as React from 'react';
import { Card, Box } from '@mui/material';
//import { APIContext } from '../../api/Api';
import { ProjectContext } from '../Project';
import {
    DataGrid,
    GridToolbarQuickFilter,
  } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { GridActionsCellItem } from '@mui/x-data-grid';
import ArticleIcon from '@mui/icons-material/Article';


export default function ProjectCompanyList(props) {
    const [ tableRows, setRows ] = React.useState([])
    const [ selectionModel, setSelectionModel] = React.useState([]);

    const columns = React.useMemo(() => {
        return [
            //{ field: 'id', headerName: 'ID', type: 'number', width: 70 },
            //{ field: 'identifier', headerName: 'Identifier', width: 130 },
            { field: 'name', headerName: 'Name', width: 300 },
            { field: 'sector', headerName: 'Sector', width: 200 },
            { field: 'country', headerName: 'Country', width: 100 },
            { 
                headerName: 'Details',
                field: 'actions',
                type: 'actions',
                width: 80,
                getActions: (params) => [
                    <GridActionsCellItem icon={<Link to={process.env.PUBLIC_URL + `/data/company/${params.id}`} onClick={props.closeDialog} className='destyle'>
                        <ArticleIcon />
                    </Link>}  />
                ]
            }
            
        ]
    }, [props.closeDialog]);


    // Select & Save to Project Context
    //const ApiC = React.useContext(APIContext)
    const ProjC = React.useContext(ProjectContext)

    /* COMPANY LIST ROWS */
    React.useEffect(() => {
        if (ProjC.AllCompaniesData === undefined) return
        let newRowObj = []
        for(let i = 0; i < ProjC.AllCompaniesData.length;i++) {
            let curComp = ProjC.AllCompaniesData[i]
            let tempObj = { 
                id: curComp.id, 
                identifier: curComp.identifier,
                name: curComp.name,
                sector: curComp.sector,
                subgroup: curComp.subgroup,
                ticker: curComp.ticker,
                country: curComp.country,
                actions: {}
            }

            newRowObj.push(tempObj)
        }

        setRows(newRowObj)
    }, [ProjC.AllCompaniesData])
    
    // Set Checks Below    
    React.useEffect(() => {
        //console.log(`[ project company list update ]`)
        if (ProjC.selectedCompanyData === undefined || ProjC.selectedCohort === undefined) return
        let idMap = ProjC.selectedCompanyData.map(r => r.id)
        
        if (selectionModel !== idMap) {
            setSelectionModel(idMap)
        }

    }, [ProjC.selectedCompanyData, ProjC.selectedCohort])
    
    if (ProjC.AllCompaniesData === undefined || props.selectedCohort === undefined || props.selectedCohort === 0) return

    // Hide List if Default List
    if (ProjC.IsDefaultNameOrNot(ProjC.selectedCohort)) return null

    return (
        <div>
            <Card>
                <div style={{ height: 800, width: '100%' }}>
                <DataGrid
                    rows={tableRows}
                    columns={columns}

                    components={{ Toolbar: QuickSearchToolbar }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector

                    pagination
                    
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={async (ids) => {
                        // Set New Companies on change
                        ProjC.SetCompanies(ids)
                        
                        // extract list on change 
                        //let curCohort = newList.filter(r => r.id === ProjC.selectedCohort)
                        //setSelectionModel(curCohort[0].companies);
                    }}
                />
                </div>
        </Card>
        </div>

    );
}


function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: '10px',
          pb: '1px',
          color: '#fff',
          backgroundColor: '#2e374b'
        }}
      >
        <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
                searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
            autoComplete={'off'}
            sx={{
                border: '1px solid #fff9',
                color: '#fff !important',
                padding: '10px',
                display: 'flex',
                marginBottom: '20px',
                backgroundColor: '#FAFAFA',
                borderRadius: '5px'
            }}
        />
      </Box>
    );
  }