import * as React from 'react'
import { Grid, Box, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Slider } from "@mui/material"
import { useParams } from 'react-router-dom';
import { APIContext } from '../../../components/api/Api';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import { IsNotGuest, IsGuest, AuthContext } from '../../../components/cognitoauth/auth/Auth';



LicenseInfo.setLicenseKey('91ead7de2936caf6cbb60f548b00ac4aTz00ODIyNixFPTE2OTA3Mzg5OTE5MDMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');



export const CompanyDetailedAdmin = (props) => {
    const [ companyData, setCompanyData ] = React.useState()


    const [ colData, setColData ] = React.useState([])
    const [ rowData, setRowData ] = React.useState([])
    const [ allD, setAllD ] = React.useState([])


    const ApiC = React.useContext(APIContext)
    const AuthC = React.useContext(AuthContext)


    let { companyId } = useParams();
    React.useEffect(() => {
        // Figure guest and endpoint

        ApiC.GetRealizedCompanyById(companyId).then((res) => {
            setCompanyData(res)

            // create object to pass to formatdata

            // format data

        })



    }, [ApiC, companyId, AuthC.isGuest])


    React.useEffect(() => {
        if (companyData === undefined) return
        console.log("[ loaded data ]")
        console.log(companyData.date)
        for (let i=0; i < companyData.length; i++)
        {
            console.log('yo')
        }
    }, [companyData])


    const LoadedOrNot = (x) => {
        if ((x === undefined) || (companyData === undefined))return ''
        return x
    }


    const DisplayData=companyData.map(
        (row)=>{
            return(
                <tr>
                    <td>{row.key}</td>
                    <td>{row.name}</td>
                    <td>{row.pillar}</td>
                </tr>
            )
        }
    )


    return (

        <div  className='reportPage' style={{display: 'flex', flexDirection: 'row'}}>
            <div className='reportCard' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <div><h1>Admin Edit Company Data / <span style={{fontWeight: 'normal'}}>{ companyData !== undefined ? companyData.name : ''}</span></h1></div>
                <table id="simple-board">
                    <tbody>
                    {DisplayData}
                    </tbody>
                </table>




            </div>
        </div>
    )
}
