import * as React from 'react'
import { Navigate } from 'react-router';
import { AuthIsNotSignedIn, AuthIsSignedIn } from '../auth/Auth';
import { UpdateEmail } from './subforms/UpdateEmail';
import { UpdatePassword } from './subforms/UpdatePassword';

export const UpdateProfile = (props) => {
   
    return (
        <>
            <AuthIsSignedIn>
                <UpdateEmail />
                <UpdatePassword />
            </AuthIsSignedIn>
            <AuthIsNotSignedIn>
                <Navigate to='/' />
            </AuthIsNotSignedIn>
        </>
    )
}
