import * as React from 'react'
import { Link } from "@mui/material"
import { ManageAccounts, BusinessCenter, BackupTable, AccountTree } from '@mui/icons-material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// Views Below
import { UserAdmin } from './adminviews/UserAdmin'
import { FileManagement } from './adminviews/FileManagement'
import { ProjectHome } from './adminviews/ProjectHome'
import { SystemAdmin } from './adminviews/SystemAdmin'
import { CompanyListEdit } from './adminviews/CompanyList'
import { CompanyDetailedAdmin} from "./adminviews/CompanyDetailedAdmin";

// Is Super Admin?
import { AuthIsSuper } from '../../components/cognitoauth/auth/Auth'

class AdminView {
    constructor(name, view, icon) {
        this.name = name;
        this.view = view;
        this.icon = icon;
    }
}

const views = [
    new AdminView("company list", <CompanyListEdit />, <FormatListBulletedIcon />),
    new AdminView("project home", <ProjectHome />, <BusinessCenter />),
    new AdminView("system admin", <SystemAdmin />, <AccountTree />),
    new AdminView("file management", <FileManagement />, <BackupTable />),
    new AdminView("user admin", <UserAdmin />, <ManageAccounts />),

    /*new AdminView("explore data", <Navigate to="/data/explore"/>, <People />)*/
]

export const AdminHome = (props) => {
    const [ view, setView ] = React.useState(undefined)
    const [ viewContent, setViewContent ] = React.useState(undefined)

    const handleViewChange = (gotoView) => {
        // Get View
        //console.log("handleViewChange", gotoView);
        for(var i = 0; i < views.length; i++) {
            if (views[i].name === gotoView) {
                setView(views[i])
                setViewContent(views[i].view)
            }
        }
    }

    React.useEffect(() => {
        setView(views[0])
        setViewContent(views[0].view)
    }, [])

    return (
        <AuthIsSuper>
            <div className='adminPage background-grey'>
                { /* Sidebar */ }
                <div className='navigation-sidebar background-dark-blue'>
                    {
                        views.map((curView) => {
                            return (
                                <div className='navigation-link'>
                                    <Link
                                        underline="none"
                                        className='capitalize'
                                        color="inherit"
                                        onClick={(e) => {
                                            handleViewChange(curView.name);
                                        }}
                                    >
                                        <div>
                                            { curView.icon }
                                            { curView.name }
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>


                <div className='adminBody' style={{display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                    <div className='' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>

                        <div className={'breadcrumb'}>
                            { view !== undefined ? "/" + view.name : null }
                        </div>

                        <div>
                            { viewContent !== undefined ? viewContent : null }
                        </div>
                    </div>
                </div>

            </div>
        </AuthIsSuper>
    )
}
