import { Container } from '@mui/material'
import * as React from 'react'
import { ForgotPasswordForm } from '../../components/cognitoauth/views/ForgotPasswordForm'

export const ForgotPassword = (props) => {

    return (
        <Container>
            <ForgotPasswordForm />
        </Container>
    )
}