import * as React from 'react'
import {Box, FormControl, Select, MenuItem, Grid, InputLabel, Container, FormControlLabel, Switch} from "@mui/material"
import {ManageProjectsToolbar, ProjectContext} from '../../components/contexts/Project'
import { APIContext } from '../../components/api/Api'
import { Link } from 'react-router-dom'
import {FileDownloadButton} from "./components/PDFCreate";
//import { IsNotGuest, IsGuest } from '../../components/cognitoauth/auth/Auth'
//import { StatusContext } from '../../components/contexts/Status'

export const Explore = (props) => {
    const [ cohortData, setCohortData] = React.useState()
    const [ selectedCompany, setSelected ] = React.useState('')
    const [ selectedList, setSelectedList ] = React.useState([])

    const handleSelectedCompany = (e) => { setSelected(e.target.value)}

    const ProjC = React.useContext(ProjectContext)
    const ApiC = React.useContext(APIContext)


    const UpdateChartByCohortId = React.useCallback(async (id) => {
        ApiC.CategoryRank(id).then((res) => {
            console.log("[ cohort chart data ]")
            setCohortData(res)
        })
    }, [ApiC])

    React.useEffect(() => {
        console.log(`[ explore update | new cohort ${ProjC.selectedCohort}]`)
        UpdateChartByCohortId(ProjC.selectedCohort)

        // set company list based on cohort
        if (ProjC.selectedCompanyData !== undefined) {
            let compMap = ProjC.selectedCompanyData.map((comp, i) => {
                return {"name": comp.name, "id": comp.id}
            })
            setSelectedList(compMap)
        }

    }, [ProjC.selectedCohort, ProjC.selectedCompanyData, UpdateChartByCohortId])

    React.useEffect(() => {
        // set selected company list to default
        if (ProjC.selectedCompanyData === undefined && cohortData !== undefined) {
            // set list
            //console.log("[ list off cohort data ]") // Take off People key list
            //console.log(cohortData)
            let selMap = cohortData["People"].map((comp) => ({"name": comp.name, "id": comp.id}))
            setSelectedList(selMap)
        }

    }, [cohortData, ProjC.selectedCompanyData])


    if (cohortData === undefined) return (
        <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h1>Loading</h1>
        </div>
    )
    return (

        <Grid className='reportPage' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <div className='reportToolbar' style={{display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center'}}>

                <ManageProjectsToolbar />
                <Box sx={{ minWidth: 250, padding: '10px 0' }}>
                    <FormControl style={{display: 'flex'}}>
                        <InputLabel id="label-highlight-company">Highlight Company</InputLabel>
                        <Select
                            labelId="label-highlight-company"
                            value={selectedCompany}
                            onChange={handleSelectedCompany}
                            label="Highlight Company"
                        >
                            <MenuItem value={''} key={''}>None</MenuItem>
                            { selectedList.map((comp) => (
                                <MenuItem value={comp.id} key={comp.id}>{ comp.name }</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <div style={{marginLeft: "auto"}}>
                    <FileDownloadButton />
                </div>

            </div>
            <div className='reportCard' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center', flexWrap: 'wrap'}}>
                    <div className='headers'>
                        <div className='header-one'>SocialMarks&trade; Score Summary</div>
                    </div>
                    <div>

                    </div>
                </div>
                { /* Categories Here */ }

                <ExploreComponent selectedCompany={selectedCompany} cohortData={cohortData} />

            </div>
        </Grid>
    )
}


export const IFrameExplore = () => {
    const [ cohortData, setCohortData] = React.useState()
    const ApiC = React.useContext(APIContext)

    React.useEffect(() => {
        ApiC.CategoryRank().then((res) => {
            //console.log("[ cohort chart data ]")
            setCohortData(res)
        })
    }, [])

    return (
        <Container style={{padding: '0'}}>
            <ExploreComponent cohortData={cohortData} demo={true} />
        </Container>
    )
}

const ExploreComponent = ({ selectedCompany, cohortData, demo}) => {
    /* DISPLAY FUNCTIONS */
    const RenderBar = (score, max, min, companyObj) => {
        let percent = Math.abs(((parseInt(score) - parseInt(min)) * 100) / (parseInt(max) - parseInt(min))).toFixed()
        let widthCalc = (parseInt(percent) * 0.5) + 35
        return (
            <div style={{backgroundColor: (parseInt(companyObj.id) === parseInt(selectedCompany)) ? '#F15619' : '#00BED6', width: widthCalc}}>
                <div  className='lil-padding'>{ Math.round(score) }</div>
            </div>
        )
    }

    const DisplayColumn = (column) => {
        if (cohortData === undefined) return

        // Get Data
        let dataCol = cohortData[column]
        if (dataCol === undefined) return

        // Format Data to object
        dataCol.sort((a, b) => (parseInt(a.score) > parseInt(b.score)) ? -1 : (parseInt(b.score) > parseInt(a.score)) ? 1 : 0)
        let minScore = parseInt(dataCol[0].score)
        let maxScore = parseInt(dataCol[dataCol.length - 1].score)

        return (
            <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <h2 style={{fontWeight: 'normal', height: '30px'}}> {column} </h2>
                <div>
                    { dataCol.map((item, i) => (
                        <div key={i} style={{display: 'flex', alignItems: 'center', marginTop: '5px', height: '47px'}}> { /*  HEIGHT ADJUSTMENT PER BAR */ }
                            <div className='score-bar' style={{maxWidth: '100px'}}>
                                <div style={{width: '80px'}}>
                                    { RenderBar(item.score, minScore, maxScore, item) }
                                </div>
                            </div>
                            <div className='score-position text-salmon-1' style={{fontSize: '0.8em', fontWeight: 'bold'}}>{i + 1}</div>
                            <div valign="center" className='score-label' style={{ color: (parseInt(item.id) === parseInt(selectedCompany)) ? '#F15619' : '', textAlign: 'left' }}>

                                { demo !== true ? (
                                    <>
                                        <Link to={process.env.PUBLIC_URL + `/data/company/${item.id}`} className="destyle" >
                                            <div data-comp={item.id} style={{fontSize: '0.9em'}} >{item.name}</div>
                                        </Link>
                                    </>
                                ) : (
                                    <div data-comp={item.id} style={{fontSize: '0.9em'}} >{item.name}</div>
                                )}

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className='blue-card' style={{minHeight: '580px'}}>
            <h2 className='uppercase' style={{marginBottom: '1.5rem'}}>Category Rankings</h2>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                { /* Column Display Here */ }
                <Grid container>
                    <Grid item xs={12} md={3} className='score-table-2' >
                        { DisplayColumn("Overall") }
                    </Grid>
                    <Grid item xs={12} md={3} className='score-table-2' >
                        { DisplayColumn("People") }
                    </Grid>
                    <Grid item xs={12} md={3} className='score-table-2' >
                        { DisplayColumn("Community") }
                    </Grid>
                    <Grid item xs={12} md={3} className='score-table-2'  >
                        { DisplayColumn("Brand") }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
