import * as React from 'react'
import {  TextField, Button, Container, List, ListSubheader, ListItemButton, Grid, Modal, Box, Typography } from '@mui/material'

export const UserAdmin = (props) => {
    // Invite Below
    const [inviteEmail , setInviteEmail] = React.useState('')
    const handleChangeInvite = async (e) => {setInviteEmail(e.target.value)}
    const handleInvite = async () => {
        // API CALL TO INVITE inviteEmail here
        setInviteEmail('')
    }

    // Set User Below
    const [ localUserInfoId, setLocalUserInfoId ] = React.useState(0)
    const handleSetUser = async(id) => {
        // API CALL TO GET USER INFO HERE
        setLocalUserInfoId(id)
    }



    return (
        <Container style={{paddingTop: '20px'}}>
            <Grid container spacing={2}>   
                <Grid item xs={12} md={12} className='admin-box' style={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <h2>Invite User</h2>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <TextField value={inviteEmail} onChange={handleChangeInvite} fullWidth label="Email" />
                        <Button variant="contained" color="primary" onClick={handleInvite} style={{margin: '10px'}}>Invite</Button>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} className='reportCard' style={{marginBottom: '50px'}}>
                    <h2>Current Users</h2>
                    <UserList setUserInfoId={handleSetUser} localUserInfoId={localUserInfoId} />
                </Grid>

                { /* 
                <Grid item xs={12} md={12} className='admin-box' style={{marginBottom: '20px'}}>
                    <UserInfo userSelected={localUserInfoId} deleteUser={deleteUser} />
                </Grid>
                */}
            </Grid>
        </Container>
    )
}

// User List
const UserList = ({setUserInfoId, localUserInfoId}) => {
    const TypesOfUsers = ['Administrator', 'Normal']

    return (
        <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          boxShadow: '0 0 2px 2px #0001',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 300,
          '& ul': { padding: 0 },
        }}
        subheader={<li />}
      >
        {TypesOfUsers.map((sectionId) => (

          <li key={`section-${sectionId}`}>
            <ul>
              <ListSubheader>{`${sectionId}`}</ListSubheader>
              {[1,2,3,4,5].map((item) => (
                    <ListItemButton onClick={() => setUserInfoId({id: item, category: sectionId})} key={`item-${sectionId}-${item}`} selected={localUserInfoId.id === item ? true : false}>
                        <UserInfo localUserInfoId={localUserInfoId}  index={item} />
                    </ListItemButton>
              ))}
            </ul>
          </li>
        ))}
      </List>
    )
}


const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

// User Selected
const UserInfo = ({localUserInfoId, index}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Handle Delete User
    const deleteUser = (user) => {
        // API CALL TO Delete user Here here
        console.log(`Delete user ${user.id}`)
    }

    if (localUserInfoId === undefined) return null

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button onClick={handleOpen} variant="contained">View</Button>
                <div style={{marginLeft: '20px'}}>User { index } </div>
            </div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={ModalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                <h2>User Information</h2>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div style={{display: 'flex', flexDirection: 'column', minHeight: '200px', minWidth: '400px'}} >
                        <div >
                            { localUserInfoId === 0 ? (
                                <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <h3>Please Select a User for the information</h3>
                                    </div>
                                </div>
                            ): (
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <div style={{alignSelf: 'flex-end'}}><Button color="error" variant='outlined' onClick={() => deleteUser(localUserInfoId)}>Delete</Button></div>
                                    <div>User Selected { localUserInfoId.id } - {localUserInfoId.category}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </Typography>
            </Box>
            </Modal>
        </div>


    )
}
