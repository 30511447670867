import * as React from 'react'
import {
    FormControl,
    Grid,
    Switch,
    Select,
    MenuItem,
    InputLabel,
    IconButton
} from "@mui/material";

import {
    GridRowModes,
    DataGridPremium,
    GridToolbarContainer,
    GridActionsCellItem,
}
    from '@mui/x-data-grid-premium';

import LinearProgress from '@mui/material/LinearProgress';
import { APIContext } from '../../../components/api/Api';
import { LicenseInfo } from '@mui/x-license-pro';
import { FormControlLabel } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


// Test Data
import {ManageProjectsToolbar, ProjectContext} from '../../../components/contexts/Project';
import {ManageProjectsDialog} from "../../../components/contexts/ManageDialog";
import {ViewColumn, ViewList} from "@mui/icons-material";
import {useParams} from "react-router-dom";

LicenseInfo.setLicenseKey('91ead7de2936caf6cbb60f548b00ac4aTz00ODIyNixFPTE2OTA3Mzg5OTE5MDMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


export const CompanyDataEditor = (props) => {
    const [ exploratoryData, setExploratoryData ] = React.useState([])
    const [ companyData, setCompanyData ] = React.useState()

    const [ colData, setColData ] = React.useState([])
    const [ rowData, setRowData ] = React.useState([])
    const [ allD, setAllD ] = React.useState([])

    const [ category, setCategory ] = React.useState('people')
    //const [ subcategory, setSubcategory ] = React.useState('none')
    const handleChangeCategory = (e) => { setCategory(e.target.value)}
    //const handleChangeSubcategory = (e) => { setSubcategory(e.target.value)}

    const ApiC = React.useContext(APIContext)
    const ProjC = React.useContext(ProjectContext)


//--------

    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStart = (params, event) => {
        console.log("handleRowEditStart", params, event)
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        console.log("handleEditClick:id", id)
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        console.log("handleSaveClick:id", id)
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        console.log("handleSaveClick:id", id)
        setRowData(rowData.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rowData.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRowData(rowData.filter((row) => row.id !== id));
        }
    };
/*
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRowData(rowData.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };
*/
//--------




    // Filtering
    //const [ subList, setSublist ] = React.useState([])

    let { companyId } = useParams();
    //// LOAD COHORT DATA IN EXPLORATORY
    React.useEffect(() => {
        console.log("[ exploratory cohort ]")
        ApiC.GetAdminExploratoryByCompanyID(companyId).then((res) => {
            setExploratoryData(res)
        })
    }, [ProjC.selectedCohort, ApiC, ProjC.selectedCompanyData])


    React.useEffect(() => {
        ApiC.GetCompanyMetaById(companyId).then((res) => {
            setCompanyData(res)
        })
    },[ApiC, companyId])

    // React.useEffect(() => {
    //     // Figure guest and endpoint
    //
    //         ApiC.GetAdminCompanyMetaById(companyId).then((res) => {
    //             setCompanyData(res)
    //         })
    //
    //
    //
    // }, [ApiC, companyId])
    // console.log(exploratoryData);
    // React.useEffect(() => {
    //     if (companyData === undefined) return
    //     console.log("[ loaded data ]")
    //
    // }, [companyData])

    // Onload Default Category
    React.useEffect(() => {

        ApiC.GetAdminExploratoryByCompanyID(companyId).then((res) => {
            setExploratoryData(res)
        })

    }, [ProjC.selectedCohort, ApiC])


    React.useEffect(() => {
        //console.log("[ data loaded ]")
        let lineObj = FormatData(exploratoryData, {category: category}, rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick)
        //console.log("lineObj", lineObj)
        //setClassNames(lineObj.classNames)
        setAllD(lineObj.data)
        setRowData(lineObj.rows)
        setColData(lineObj.columns)


    }, [exploratoryData, category])


    const [snackbar, setSnackbar] = React.useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = React.useCallback(
        async (newRow) => {

            // foprmat data to send to back end
            let myData = {};
            myData[newRow["field"]] =  newRow['Raw Data'];

            // Make the HTTP request to save in the backend
            const response = await ApiC.UpdateCompanyField(newRow.es_key, newRow.company_id, myData);

            setSnackbar({ children: 'Change Saved Successfully - Please allow 2 minutes to fully process', severity: 'success' });
            return response;
        },
        [rowData],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    return (


        <Grid className='reportPage' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>

            <div className='reportToolbar' style={{display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center'}}>
                <FormControl style={{minWidth:'200px'}}>
                    <InputLabel id="label-category-select">Category</InputLabel>
                    <Select
                        labelId="label-category-select"
                        label="Category"
                        id="category-select"
                        value={category}
                        onChange={handleChangeCategory}
                    >
                        <MenuItem value={'all'} style={{textTransform: 'capitalize'}}>All</MenuItem>
                        {
                            ["people", "brand", "community"].map((item, i) => (
                                <MenuItem value={item} key={i}><span style={{textTransform: 'capitalize'}}>{ item }</span></MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <div style={{marginLeft: "auto"}}>

                </div>

            </div>

            <div className='reportCard' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>

                    <div className='headers' style={{display: 'flex', flexDirection:'column'}}>
                        <div className='header-two'>Company Data Editor</div>


                        <div className='header-one capitalize'>{ companyData !== undefined ? companyData.name : ''}</div>

                    </div>

                    <div className={'legend'}>
                        <div className={'labels'}>Lowest<br />Quintile</div>
                        <div className={'color-spectrum'}>
                            <div className={'color-block level5'}></div>
                            <div className={'color-block level4'}></div>
                            <div className={'color-block level3'}></div>
                            <div className={'color-block level2'}></div>
                            <div className={'color-block level1'}></div>
                        </div>
                        <div className={'labels'}>Highest<br />Quintile</div>
                    </div>
                </div>

                <div className='dataGrid' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                    <DataGridPremium
                        rows={rowData}
                        columns={colData}
/*
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        components={{
                            //Toolbar: EditToolbar,
                        }}
                        componentsProps={{
                            toolbar: { setRowData, setRowModesModel },
                        }}

 */

                        processRowUpdate={processRowUpdate}
                        // onProcessRowUpdateError={handleProcessRowUpdateError}
                        experimentalFeatures={{ newEditingApi: true }}


                        //autoHeight

                        /*
                        className={''}


                        initialState={{
                            pinnedColumns: { left: ['key'] },
                        }}

*/
                        getCellClassName={(params) => {
                            //console.log(`${params.field} = ${params.row.key}`)
                            //console.log(params)
                            //let myClass = "background-white"
                            let myClass = ""
                            let r = allD.filter((item) => item.company_name === params.field && item.data_name === params.row.key)
                            if (r.length >= 1) {
                                myClass = r[0]["class"]
                            }
                            return myClass

                        }}

                    />
                    {!!snackbar && (
                        <Snackbar
                            open
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            onClose={handleCloseSnackbar}
                            autoHideDuration={6000}
                        >
                            <Alert {...snackbar} onClose={handleCloseSnackbar} />
                        </Snackbar>
                    )}
                </div>
            </div>

        </Grid>
    )
}


const FormatData = (data, filters, rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick) => {

    console.log("data", data);
    if (data === undefined) {
        return { rows: [], columns: []}
    } else {

    }

    // Holds an array of all data received before formatting
    let AllData = []
    //let cn = []  // {"companyname": "", "key": "", "class": "classNAME"}

    // Get HoverMap
    let hoverMap = data.map((item) => {
        // get rows and add to rows obj
        let ald = item.data.map((data) => {
            let newObj = {
                "company_name": item.company_name,
                "data_name": data.name,
                hoverText: data.hoverText
            }
            return newObj
        })

        return ald
    })

    // Contains Hover Data
    const hoverMapData = [].concat(...hoverMap)


    let columns = data.map((item) => {
        // get rows and add to rows obj

        //console.log("item", item);


        item.data.map((data) => {
            let newObj = {
                "company_name": item.company_name,
                "id": item.company_id,
                key: data.key,
                "data_name": data.name,
                category: data.category,
                subcategory: data.sub_category,
                value: data.display_value, //`${data.value}:${data.class}`
                class: data.class,
                es_key: data.es_key,
                hoverText: data.hoverText
            }

            AllData.push(newObj)

            // add to classname array
            //cn.push({"id": item.company_id, "company_name": item.company_name, "key": data.name, "class": data.class })
            /*
            if (filters !== null && filters !== undefined && filters.category !== 'all') {
                // Category Filter
                if (filters.category !== data.category) {
                    return undefined
                }
            }
            */

            //console.log("data", data);

            // If it matches push to all data
            return newObj
        })

        console.log("item", item)

        // add column to array
        return {
            editable: false,
            field: item.company_name,
            headerName: item.company_name,
            company_id: item.company_id,
            width: 150,
            //"companyId": companyId,
            /*
            renderCell: (params) => {

                // get hovermaptext
                //console.log("cell item", item)
                //console.log("cell params", params)
                const itemL = hoverMapData.filter((item) => item.company_name === params.field && item.data_name === params.row.key)
                return (
                    <Tooltip title={ itemL[0].hoverText.split('|').map(i => <>{i}<br /></>) } >
                        <span className="csutable-cell-trucate">{params.value}</span>
                    </Tooltip>
                )



                return (
                    <span className="csutable-cell-trucate">{params.value}</span>
                )
            }
            */
        }

    })


    if (columns[1] !== undefined && columns[1] !== null) {
        columns[1]["editable"] = true;
    }

    // AllData filtering
    let filteredD = []

    if (filters.category !== 'all') {
        for(let x = 0; x < AllData.length; x++) {
            let item = AllData[x]
            if (filters !== null && filters !== undefined && filters.category !== 'all') {
                // Category Filter
                if (filters.category === item.category) {
                    filteredD.push(item)
                }
            }
        }
    } else {
        filteredD = AllData
    }



    // Add field to columns
    columns = [{field: "key", headerName: "Name", width: 270, id: 0},...columns]
    /*
    columns = [...columns, {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
                console.log("\n\n!!!TRUE!!!!\n\n")
            } else {
                console.log("id/isInEditMode", id, rowModesModel);
            }
            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />,
            ];
        },
    }];
    */


    // Get Unique Subkeys
    let allEsKeys = filteredD.map(r => r.es_key).filter((v, i, s) => s.indexOf(v) === i)
    let allkeys = filteredD.map(r => r.key).filter((v, i, s) => s.indexOf(v) === i)
    let allCompanies = filteredD.map(r => r.company_name).filter((v, i, s) => s.indexOf(v) === i)

    // Loop All Data to format row data
    let newRows = []
    for(let i = 0; i < allkeys.length; i++) {
        // get key
        let curKey = allkeys[i]

        // Loop each company
        let newObj = {
            id: i,
            field: curKey,
            es_key: allEsKeys[1],
            company_id: columns[1].company_id
        }

        // Make Row Here
        allCompanies.map((indCompany) => {
            newObj[indCompany] = GetValue(filteredD, indCompany, curKey)
            return undefined
        })

        newObj["key"] = PrettyName(filteredD, curKey)


        // Check if Dynamic Rendering
        if (!newRows.includes(newObj)) {
            newRows.push(newObj)
        }
    }

    //console.log("columns", columns);
    //console.log("newRows", newRows);

    return { rows: newRows, columns: columns, data: filteredD}

}


function RowContainsId(name, rows) {
    for(let y = 0; y < rows.length; y++) {
        if (name === rows[y].company_name) {
            return y
        }
    }
    return undefined
}

// Get Value of Company through cell
function GetValue(AllData, companyName, key) {
    let newFiltered = AllData.filter(item => item.company_name === companyName && key === item.key ? true : false)
    if (newFiltered.length > 0) {
        return newFiltered[0].value
    }
}

// Pretty Name From Key
function PrettyName(AllData, key) {
    let pn = AllData.filter(item => item.key === key ? item.data_name : false)
    return pn[0].data_name
}
