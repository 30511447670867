import * as React from 'react'
import { Grid } from '@mui/material'
import { APIContext } from '../../../components/api/Api';
import { ManageProjectsToolbar, ProjectContext } from '../../../components/contexts/Project';
import { Link } from 'react-router-dom'

class DataLine {
    constructor(company, section, score, company_id) {
        this.company = company;
        this.section = section;
        this.score = score;
        this.company_id = company_id;
    }
}

export const VerticalChart = (props) => {
    const ApiC = React.useContext(APIContext)
    const ProjC = React.useContext(ProjectContext)

    const [ selectedCompanyData, setSelectedCompanyData ] = React.useState([])
    const [ selectedDatalines, setSelectedDatalines ] = React.useState([])
    const [ filteredAssessmentData, setFilteredAssessmentData ] = React.useState([])
    const [ sectorAverage, setSectorAverage ] = React.useState('')

    const [ localData, setLocal ] = React.useState()

    //////////////////////////////////////////////////////
    /* NEW PROVIDER IMPLEMENTATION */   // RENDER ROWS ON SELECTED COMPANY DATA
    //////////////////////////////////////////////////////
    React.useEffect(() => {
        let curCompData = ProjC.GetCurrentCohortCompanyData()

        if (curCompData === undefined || props.AllScores === undefined) return
        console.log("[ current comp data ]")
        console.log(curCompData)
        let compArr = curCompData.filter(item => props.selectedCompanies.includes(item.id))
        setSelectedCompanyData(compArr)

        if (compArr.length === 0) {
            setSelectedDatalines([])

            // Load Default Cohort company rows
            let newRows = curCompData.map((item) => {
                let newObj = {"company_name": item.name, "id": item.id }
                return newObj
            })

            if (!includesCompaniesAlready(props.dynRows, newRows)) {
                props.setRows(newRows)
            }

        }
    }, [selectedCompanyData, ProjC.selectedCohort]) // onload for faster caching
    

    // Reload scores on category change & score updates
    React.useEffect(() => {
        if (props.category !== undefined && props.category.key !== undefined ) {
            ApiC.GetAssessmentsByCategory(props.category.key, ProjC.selectedCohort).then((res) => {
                setLocal(res)
                if (res["sector_average"] !== undefined) {
                    // Sector Average
                    setSectorAverage(res["sector_average"])
                }
            })
        }
    }, [props.category, ProjC.selectedCohort])
    
    React.useEffect(() => {
        if (localData === undefined || localData.companies === undefined) return
        let filteredData = localData.companies.filter((item) => props.selectedCompanies.includes(item.company_id))
        if (filteredData.length > 0) {
            setFilteredAssessmentData(filteredData)
        }


        if (localData["companies"].length > 0) {
            props.setAllScores(localData["companies"])
        }
    }, [localData, props.selectedCompanies])


    // FILTERED ASSESSMENT DATA
    //////////////////////////////////////////////////////
    React.useEffect(() => {
        if (filteredAssessmentData === undefined || filteredAssessmentData.length === 0) return


        // Get Keys from Data Initially
        let keyMap = Object.keys(filteredAssessmentData[0]).filter((item) => {
            if ((item !== "company_id") && (item !== "company_name") && (item !== "sector") && (item !== "total_score")) {
                return item
            }
            return false
        })

        // add columns
        if (props.columns.length > 0) {
            let newColumns = [{ field: 'company_name', headerName: 'Name', width: 250 }]
            keyMap.map((key) => {
                // each key
                let simpleObj = { field: CleanNameForColumnKey(key), headerName: key, width: 250 }
                newColumns.push(simpleObj)
                return key
            })
            props.setColumns(newColumns)
        }

        // keyMap = KEYS OF DATA
        let allData = []
        for(var i = 0; i < filteredAssessmentData.length; i++) {
            // each company
            let curCompany = filteredAssessmentData[i]
            let objs = keyMap.map((key) => {
                // Current Selected company with key [people,comm,etc]
                let lineObj = { company: curCompany.company_id, company_name: curCompany.company_name, score: curCompany[key], key: key}
                //console.log(lineObj)
                return lineObj
            })

            allData = [...allData, ...objs]
        }


        // ALL DATA HAS DATA MAP OF CURRENTLY SELECTED COMPANIES
        let allDataLines = allData.map((dataL) => {
            let dataVal = (typeof(dataL.score) === "string") ? dataL.score : dataL.score.toFixed(2)
            let dataLine = new DataLine(dataL.company_name, dataL.key, dataVal, dataL.company)
            return dataLine
        })

        setSelectedDatalines(allDataLines)

    }, [filteredAssessmentData])



    //////////////////////////////////////////////////////
    //////////////////////////////////////////////////////
    const RenderChartSections = () => {
        const data = selectedDatalines
        let minScore = 0
        let maxScore = 0

        let companies = []
        let sections = []
        for(let i = 0; i < data.length; i++) {
            if (!sections.includes(data[i].section)) {
                sections.push(data[i].section)
            }

            if (!companies.includes(data[i].company)) {
                companies.push(data[i].company)
            }

            if (parseFloat(data[i].score) > parseFloat(maxScore)) {
                maxScore = data[i].score
            }
        }



        const ExtractCompanies = (section, dataArray) => {
            var returnedSection = []
            var topScore = 0;
            for (let i =0; i<dataArray.length; i++) {
                if ((!returnedSection.includes(data[i].company)) && (section === data[i].section)) {
                    if (dataArray[i].section === section) {
                        returnedSection.push(dataArray[i])
                        if(dataArray[i].score > topScore) {
                            topScore = dataArray[i].score;
                        }
                    }
                }
            }

            // Make Bar Charts Here
            const barHeight = (score, max, min) => {
                let percent = Math.abs(((score - min) * 100) / (max - min)).toFixed()
                let heightCalc = (parseInt(percent) * 350) / 100 // Calculate for total height of 400px?
                return heightCalc
            }



            return (
                <div style={{display: 'flex', justifyContent: 'space-evenly', flexGrow: 1}}>
                    { returnedSection.map((companyData) => {
                        return (
                            <div key={companyData.id} className={ companyData.score === topScore ? 'top-score' : ''} style={{display: 'flex', flexDirection: 'column', padding: '5px', marginTop: 'auto'}}>
                                <div className={"bar-score"} style={{textAlign: 'center', paddingBottom: '5px'}}>{ parseFloat(companyData.score) }</div>

                                { /* Dynamic Bar Rendering */}
                                { /* Adjust width of bars of chart here */}
                                <div style={{width: '30px', height: barHeight(companyData.score, maxScore, minScore)}} className={"bar-body hoverClass"}>

                                </div>

                                <div className={"bar-label"} style={{fontSize: '0.7em', position:'relative', height:'4rem'}}>
                                    <div className={"rotate rotate-down"} style={{fontSize: '0.7em'}}>
                                        <Link to={process.env.PUBLIC_URL + `/data/company/${companyData.company_id}`} className="destyle">{ companyData.company }</Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }

        return (
            <div className="vertical-bar-cluster">
                <Grid
                    container
                    spacing={1}
                >
            { sections.map((section) => {
                return (
                    <Grid
                        item
                        xs={6}
                        md={2}
                        key={section}
                    >
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#29303f', padding: '0 1rem'}}>
                            <div><h2 style={{height: '60px', fontSize: '0.9em', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>{ section }</h2></div>
                            <div style={{minHeight: '500px', display: 'flex', flexGrow: 1}}>
                                { /* Data Here */ }
                                { ExtractCompanies(section, data)}
                            </div>
                        </div>
                    </Grid>
                )
            })}
                </Grid>
            </div>
        )
    }

    const CheckDefined = () => {
        if (props.category === undefined) return true
        return false
    }

    return (
        <div>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding:'0 0 1rem 0'}}>
                    <div className='xy-center-children' style={{textAlign: 'center'}}>
                        <h2>Sector Average</h2>
                        <div className='text-light-blue text-x-large'>{ sectorAverage }</div>
                    </div>


                    { filteredAssessmentData.map((item) => {
                        return (
                            <div className='xy-center-children' style={{textAlign: 'center'}}>
                                <h3>{ item.company_name }</h3>
                                <div className={'text-blue text-large'}>{ item.total_score }</div>
                            </div>
                        )
                    })}


                </div>


            </div>
            <div  style={{minHeight: '580px'}} className='blue-card'>
                { CheckDefined() === false ? (
                <h2 className='uppercase' style={{marginTop: 0}}><span className='text-light-blue'>{ (props.category.name)  }</span></h2>
                ): null}

                { /* Instructions Box */}
                { selectedDatalines.length === 0 ? (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '400px', textTransform: 'uppercase'}}>
                        Please select an active cohort and check the companies for data.
                    </div>
                ) : (
                    <div>
                        { /* Chart Area */ }
                        {  RenderChartSections() }
                    </div>
                )}


            </div>
        </div>
    )
}


export const CleanNameForColumnKey = (name) => {
    return name.replaceAll(" ", "_").replaceAll("/","").replaceAll("&","").replaceAll("\\","").replaceAll("(", "").replaceAll(")", "").toLowerCase()
}

function includesCompaniesAlready(dynRows, newRows) {
    let companyNameListOne = JSON.stringify(dynRows.map(r => r.company_name))
    let companyNameListTwo = JSON.stringify(newRows.map(r => r.company_name))
    return (companyNameListOne === companyNameListTwo);
}
