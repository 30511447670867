import * as React from 'react'
import { Navigate } from 'react-router'

import { AuthContext } from '../../components/cognitoauth/auth/Auth';

export const Logout = (props) => {
    const authContext = React.useContext(AuthContext)
  
    const logout = () => {
      const LogoutFunc = async () => {
          try {
              authContext.signOut()
              // Signed Out 
          } catch (err) {
              console.log(err)
          }
      } 
      LogoutFunc()
    }

    React.useEffect(() => {
        logout()
    })

    return (
        <Navigate to={'/'} />
    )
}