import * as React from 'react'
import { APIContext } from '../api/Api';
import { AuthIsNotSignedIn, AuthIsSignedIn, IsNotGuest } from '../cognitoauth/auth/Auth';
import { ManageProjectsDialog } from './ManageDialog'
import { useLocation } from 'react-router-dom'

export const ProjectContext = React.createContext()

export const ProjectProviderNew = ({children}) => {
    // COHORTS
    const [ cohortList, setCohortList ] = React.useState()
    const [ selectedCohort, setSelectedCohort ] = React.useState()

    const [ AllCompaniesData, setAllCompaniesData ] = React.useState()
    const [ selectedCompanies, setSelectedCompanies] = React.useState()
    const [ selectedCompanyData, setSelectedCompanyData ] = React.useState()


    const ApiC = React.useContext(APIContext)

    //////////////////////////////////////
    // Get Cohort
    //////////////////////////////////////
    const GetCurrentCohort = () => {
        if (cohortList === undefined) return
        let l = cohortList.filter(item => item.id === selectedCohort)
        if (l.length > 0) {
            return l[0]
        }
        return null
    }

    const GetCurrentCohortCompanyData = () => {
        return selectedCompanyData
    }


    //////////////////////////////////////
    // Update the Cohort List
    //////////////////////////////////////
    const UpdateCohortList = React.useCallback(() => {
        ApiC.ListCohorts().then(async (res) => {
            //console.log("[ updated cohorts ]")
            //console.log(res)
            /*
            const defaultList = await ApiC.PublicCohorts().then((result) => {
                // Add to Default Cohort List
                return result
            })
            */
            //setDefaultList(defaultList)
            setCohortList(res)
        })
    }, [ApiC])

    //////////////////////////////////////
    // Is Default Cohort or Not
    //////////////////////////////////////
    const IsDefaultOrNot = (cohortObj) => {
        return cohortObj.public
    }

    const IsDefaultNameOrNot = (name) => {

        if (cohortList === undefined) return false
        for(let i = 0; i < cohortList.length; i++) {
            if (cohortList[i].name === name) {
                return cohortList[i].public
            }
        }
        return false
    }


    //////////////////////////////////////
    // Cohort Management
    //////////////////////////////////////
    const GetCohortById = React.useCallback((id) => {
        if (cohortList === undefined) return null
        let curCohort = cohortList.filter(r => r.id === id)
        if (curCohort.length > 0) {
            return curCohort[0]
        }
        return null
    }, [cohortList])

    /* Changes on Selected Cohort */
    React.useEffect(() => {
        var curCohort = GetCohortById(selectedCohort)
        if (curCohort === undefined || curCohort === null || selectedCompanies === curCohort.companies) return

        console.log(`[ cohort selected ${selectedCohort}]`)
        //console.log("[ new companies ]")'
        setSelectedCompanies(curCohort.companies)
    }, [selectedCohort, GetCohortById])
    /* Changes on Selected Cohort */


    const GetSelectedCompanyData = React.useCallback(() => {
        if (selectedCompanies === undefined) return
        let data = selectedCompanies.map((selectedComp) => {

            let compDataMaybe = AllCompaniesData.filter((comp) => comp.id === selectedComp)
            if (compDataMaybe.length === 1) {
                return compDataMaybe[0]
            }

            return compDataMaybe.filter(r => (r !== null))
        })

        /////////////////// BUG FIX ?
        // take out array that randomly showed in?
        ///////////////////////////////////////////////////
        let ObjectsOnly = data.filter((item) => item.id !== undefined)
        return ObjectsOnly
    }, [AllCompaniesData, selectedCompanies])

    React.useEffect(() => {
        let compData = GetSelectedCompanyData()
        setSelectedCompanyData(compData)
    }, [selectedCompanies, GetSelectedCompanyData])

    React.useEffect(() => {
        //console.log("[ new company data ]")
        //console.log(selectedCompanyData)
    }, [selectedCompanyData])

    const SetCurrentCohort = (id) => {
        setSelectedCohort(id)
    }

    const AddCohort = async (name) => {
        let newList = await ApiC.CreateCohort(name)
        //console.log(newList)
        setCohortList(newList)
    }

    const DeleteCohort = async (id) => {
        let newList = await ApiC.DeleteCohort(id)
        setCohortList(newList)
    }


    //////////////////////////////////////
    // Company Management
    //////////////////////////////////////
    const UpdateCompanyState = async (list) => {
        //console.log("[ updating company state ]")
        //console.log(list)
        setSelectedCompanies(list)
    }

    const SetCompanies = React.useCallback(async (list) => {
        let res = await ApiC.UpdateCohort(selectedCohort, list)
        //setCohortList(res)
        UpdateCompanyState(list)
        return res
    }, [selectedCohort])


    const GetCompanyDataById = React.useCallback(async (id) => {
        let compMaybe = await ApiC.GetCompanyList().then(r => {
            let filtered = r.filter(i => parseInt(i.id) === parseInt(id))
            if (filtered.length > 0) {
                return filtered[0]
            }
            return
        })
        return compMaybe
    }, [ApiC])


    //////////////////////////////////////
    // Onload
    //////////////////////////////////////
    React.useEffect(() => {
        UpdateCohortList()
        ApiC.GetCompanyList().then(r => {
            //console.log("[ all data saving in browser ]")
            setAllCompaniesData(r)

            // Add a default all company cohort locally maybe?

        })
    }, [ApiC, UpdateCohortList])


    //////////////////////////////////////
    // Export State
    ////////////////////////////////////
    const state = {
        cohortList,
        selectedCohort,
        selectedCompanyData,
        AllCompaniesData,

        // Exported Functions
        SetCurrentCohort,
        AddCohort,
        DeleteCohort,
        GetCurrentCohort,
        GetCurrentCohortCompanyData,

        // Companies
        UpdateCompanyState,
        SetCompanies,
        UpdateCohortList,
        GetCompanyDataById,

        //
        IsDefaultOrNot,
        IsDefaultNameOrNot,
    }

    const location = useLocation();




    return (
        <ProjectContext.Provider value={state}>
            { window.location.pathname !== '/demo' ? (
                <div >
                <AuthIsSignedIn>
                    { children }
                </AuthIsSignedIn>
                <AuthIsNotSignedIn>
                    { children }
                </AuthIsNotSignedIn>
                </div> ) : (
                <div>
                    { children }
                </div>
            )}
        </ProjectContext.Provider>
    )
}

export const ManageProjectsToolbar = (selectedCohort, cohortList) => {
    return (
        <IsNotGuest>
                <ManageProjectsDialog selectedCohort={selectedCohort} cohortList={cohortList}  />
        </IsNotGuest>
    )
}


