import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {AddCircle} from "@mui/icons-material";

export const SystemAdmin = (props) => {

    const [ dataSet, setData ] = React.useState()

    const datacolumns = [
        {
            field: 'id', headerName: 'ID', width: 90
        },
        {
            field: 'company',
            headerName: 'Company',
            width: 150,
            editable: true,
        },
        {
            field: 'x',
            headerName: 'x',
            width: 150,
            editable: true,
        },

        {
            field: 'y',
            headerName: 'y',
            width: 150,
            editable: true,
        },
    ];

      const datarows = [
        { id: 1, company: 'Company 1', uploaded: new Date().toString()},
        { id: 2, company: 'Company 2', uploaded: new Date().toString()},
        { id: 3, company: 'Company 3', uploaded: new Date().toString()},
        { id: 4, company: 'Company 4', uploaded: new Date().toString()},
        { id: 5, company: 'Company 5', uploaded: new Date().toString()},
        { id: 6, company: 'Company 6', uploaded: new Date().toString()},
        { id: 7, company: 'Company 7', uploaded: new Date().toString()},
        { id: 8, company: 'Company 8', uploaded: new Date().toString()},
        { id: 9, company: 'Company 9', uploaded: new Date().toString()},
    ];

    /* COLUMNS & ROWS FOR FIRST DATA SET */
    const columns = [
        {
            field: 'id', headerName: 'ID', width: 90
        },
        {
            field: 'file',
            headerName: 'File',
            width: 150,
            editable: true,
        },
        {
            field: 'uploaded',
            headerName: 'Uploaded',
            width: 205,
            editable: true,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem icon={<EditModal rows={datarows} cols={datacolumns} onClick={() => setData(params.id)} />}  />
            ]
        }
    ];

      const rows = [
        { id: 1, file: 'File 1', uploaded: new Date().toString(), edit: 1 },
        { id: 2, file: 'File 2', uploaded: new Date().toString(), edit: 2 },
        { id: 3, file: 'File 3', uploaded: new Date().toString(), edit: 3 },
        { id: 4, file: 'File 4', uploaded: new Date().toString(), edit: 4 },
        { id: 5, file: 'File 5', uploaded: new Date().toString(), edit: 5 },
        { id: 6, file: 'File 6', uploaded: new Date().toString(), edit: 6 },
        { id: 7, file: 'File 7', uploaded: new Date().toString(), edit: 7 },
        { id: 8, file: 'File 8', uploaded: new Date().toString(), edit: 8 },
        { id: 9, file: 'File 9', uploaded: new Date().toString(), edit: 9 },
    ];



    return (
        <Container className={'adminFluid'}>
            <Grid container>
                <Grid item xs={12}>
                    <div className='admin-box-header'>
                        <h1>System Admin / Data Management</h1>
                    </div>
                </Grid>
                <Grid item xs={12} className='admin-box'>

                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>

    )
}

const ModelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: 700,
}

const EditModal = ({rows, cols}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
        <Button onClick={handleOpen}>Edit</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModelStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                <Grid container spacing={2} style={{minHeight: '700px', paddingBottom: '100px'}}>
                    <Grid item xs={12} md={12}>
                        <h3 style={{textAlign: 'center'}}>Edit Dataset</h3>
                        <DataGrid
                            rows={rows}
                            columns={cols}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Grid>
                </Grid>
            </Typography>
          </Box>
        </Modal>
      </div>
    )
}
