import * as React from 'react'
import { Container, Grid } from "@mui/material"
import { Link } from "react-router-dom"
import { AuthIsNotSignedIn, AuthIsSignedIn, AuthIsSuper } from './cognitoauth/auth/Auth';
//import { ActiveProjectContext } from './activeproject/activeproject'

export const Footer = (props) => {

    //const apiCont = React.useContext(APIContext)
    //console.log(apiCont.GetCompanyList())

    //const activeProject = React.useContext(ActiveProjectContext)
    //console.log(activeProject.activeProject)

    return (
        <div className="footer">
            <div className='footer-body MuiAppBar-colorPrimary'>
                <Container>
                    <Grid container>

                        <AuthIsNotSignedIn>
                            <Grid item xs={12} md={3} className="linkBox">
                                <Link to="/" className="destyle navigation-link">Login</Link>
                                <Link to="/auth/register" className="destyle navigation-link">Register</Link>
                                <Link to="/admin/login" className="destyle navigation-link">Admin Login</Link>
                            </Grid>
                        </AuthIsNotSignedIn>

                        <AuthIsSignedIn>
                            <Grid item xs={12} md={12} className="linkBox">
                                <AuthIsSuper>
                                    <Link to="/admin/home" className="destyle navigation-link">Admin Home</Link>
                                    { /*
                                        <Link to="/admin/user" className="destyle navigation-link">User</Link>
                                        <Link to="/admin/file/management" className="destyle navigation-link">File Management</Link>
                                        <Link to="/admin/file/processing" className="destyle navigation-link">File Processing</Link>
                                        <Link to="/admin/project" className="destyle navigation-link">Project</Link>
                                        <Link to="/admin/project/create" className="destyle navigation-link">ProjectCreate</Link>
                                        <Link to="/admin/project/active" className="destyle navigation-link">ProjectActive</Link>
                                        <Link to="/admin/data/upload" className="destyle navigation-link">DataUpload</Link>
                                        <Link to="/admin/data/entry" className="destyle navigation-link">DataEntry</Link>
                                    */}
                                </AuthIsSuper>
                                <Link to="/auth/userhome" className="destyle navigation-link">User Home</Link>
                                <Link to="/auth/techsupport" className="destyle navigation-link">Tech Support</Link>
                                <Link to="/auth/account" className="destyle navigation-link">Account</Link>
                                <Link to="/auth/invite" className="destyle navigation-link">Invite</Link>
                                <Link to="/data/explore" className="destyle navigation-link">Explore</Link>
                                <Link to="/data/vertical" className="destyle navigation-link">Vertical</Link>
                                <Link to="/data/exploratory" className="destyle navigation-link">Exploratory</Link>
                                <Link to="/data/company" className="destyle navigation-link">Company Detailed</Link>
                                <Link to="/data/forecasting" className="destyle navigation-link">Forecasting</Link>
                            </Grid>

                        </AuthIsSignedIn>

                        <Grid item xs={12} md={12} style={{paddingTop: '20px'}}>
                            <span style={{fontSize: '0.8em', fontFamily: 'sans-serif'}}>&copy; 2022 SocialMarks.  All Rights Reserved.</span>
                        </Grid>

                    </Grid>
                </Container>

            </div>
        </div>
    )
}
