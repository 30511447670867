import * as React from 'react'
import { FormControl, Grid, Select, MenuItem, InputLabel } from "@mui/material"
import { DataGridPremium, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector} from '@mui/x-data-grid-premium';
import LinearProgress from '@mui/material/LinearProgress';
import { APIContext } from '../../components/api/Api';
import { LicenseInfo } from '@mui/x-license-pro';
import Tooltip from '@mui/material/Tooltip';
import { Height } from '@mui/icons-material';


// Test Data
import {ManageProjectsToolbar, ProjectContext} from '../../components/contexts/Project';
import {FileDownloadButton} from "./components/PDFCreate";

LicenseInfo.setLicenseKey('91ead7de2936caf6cbb60f548b00ac4aTz00ODIyNixFPTE2OTA3Mzg5OTE5MDMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


export const ExploratoryDetailed = (props) => {
    const [ exploratoryData, setExploratoryData ] = React.useState([])

    const [ colData, setColData ] = React.useState([])
    const [ rowData, setRowData ] = React.useState([])
    const [ allD, setAllD ] = React.useState([])

    // TODO
    const [ orientation, setOrientation] = React.useState(false) // true(col) & false (row)
    const handleOrientation = (e) => { console.log("e0", e.target.checked); setOrientation(e.target.checked)}

    const handleOrientationNew = (e) => { console.log("e1", e); console.log("e1-target.value", e.target.value); setOrientation(e.target.value)}

    // Classes
    //const [ classNames, setClassNames] = React.useState([])

    const [ category, setCategory ] = React.useState('people')
    //const [ subcategory, setSubcategory ] = React.useState('none')
    const handleChangeCategory = (e) => { setCategory(e.target.value)}
    //const handleChangeSubcategory = (e) => { setSubcategory(e.target.value)}

    const ApiC = React.useContext(APIContext)
    const ProjC = React.useContext(ProjectContext)


    // Filtering
    //const [ subList, setSublist ] = React.useState([])

    //// LOAD COHORT DATA IN EXPLORATORY
    React.useEffect(() => {
        console.log("[ exploratory cohort ]")
        ApiC.ExploratoryByCohortId(ProjC.selectedCohort).then((res) => {
            setExploratoryData(res)
        })
    }, [ProjC.selectedCohort, ApiC, ProjC.selectedCompanyData])

    // Onload Default Category
    React.useEffect(() => {
        if (ProjC.selectedCohort !== undefined) {
            ApiC.ExploratoryByCohortId(ProjC.selectedCohort).then((res) => {
                setExploratoryData(res)
            })
        }
    }, [ProjC.selectedCohort, ApiC])


    /*
    React.useEffect(() => {
        console.log(orientation)
    }, [orientation])
    */

    // On Category Change get sub category list
        /*
    React.useEffect(() => {

        setSubcategory('none')
        ApiC.DataSubcategoryList().then((subs) => {
            let newSubs = subs.map((sub) => {
                if (category === sub.category){
                    return sub.key
                }
                return false
            })
            newSubs = newSubs.filter(r => r !== false)
            setSublist(newSubs)
        })
    }, [category])
    */
    /************************** */

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                { /* Orientation */ }
                <FormControl>
                    <Select
                        className={"dataGridToolbarSelect"}
                        value={orientation}
                        name="Orientation"
                        label="Orientation"
                        onChange={handleOrientationNew}
                    >
                        <MenuItem
                            className={"dataGridToolbarSelectItem"}
                            value={false}
                        ><Height className={"rotate-90"} fontSize={"small"}/> Companies Horizontal</MenuItem>
                        <MenuItem
                            className={"dataGridToolbarSelectItem"}
                            value={true}
                        ><Height   fontSize={"small"}/> Companies Vertical</MenuItem>
                    </Select>
                </FormControl>
                <GridToolbarExport sx={{marginLeft: "auto"}}/>
            </GridToolbarContainer>
        );
    }


    React.useEffect(() => {
        //console.log("[ data loaded ]")
        let lineObj = FormatData(exploratoryData, {category: category}, orientation)
        //console.log("lineObj", lineObj)
        //setClassNames(lineObj.classNames)
        setAllD(lineObj.data)
        setRowData(lineObj.rows)
        setColData(lineObj.columns)


    }, [exploratoryData, category, orientation])


    const currentCohort = ProjC.GetCurrentCohort();
    const currentCohortName = currentCohort !== undefined && currentCohort !== null ? currentCohort.name : 'Default Cohort';
    return (


        <Grid className='reportPage' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>

            <div className='reportToolbar' style={{display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center'}}>

                <ManageProjectsToolbar />
                <FormControl style={{minWidth:'200px'}}>
                    <InputLabel id="label-category-select">Category</InputLabel>
                    <Select
                        labelId="label-category-select"
                        label="Category"
                        id="category-select"
                        value={category}
                        onChange={handleChangeCategory}
                    >
                        <MenuItem value={'all'} style={{textTransform: 'capitalize'}}>All</MenuItem>
                        {
                            ["people", "brand", "community"].map((item, i) => (
                                <MenuItem value={item} key={i}><span style={{textTransform: 'capitalize'}}>{ item }</span></MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <div style={{marginLeft: "auto"}}>
                    <FileDownloadButton />
                </div>

            </div>

            <div className='reportCard' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>

                    <div className='headers' style={{display: 'flex', flexDirection:'column'}}>
                        <div className='header-two'>{ currentCohortName }</div>
                        <div className='header-one capitalize'>Score Breakdown / { category }</div>
                    </div>

                    <div className={'legend'}>
                        <div className={'labels'}>Lowest<br />Quintile</div>
                        <div className={'color-spectrum'}>
                            <div className={'color-block level5'}></div>
                            <div className={'color-block level4'}></div>
                            <div className={'color-block level3'}></div>
                            <div className={'color-block level2'}></div>
                            <div className={'color-block level1'}></div>
                        </div>
                        <div className={'labels'}>Highest<br />Quintile</div>
                    </div>
                </div>

                <div className='dataGrid' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                    <DataGridPremium
                        rows={rowData}
                        columns={colData}

                        rowHeight={30}
                        headerHeight={80}
                        className={'score-table'}
                        pinnedColumns={{ left: [`${ !orientation ? 'key' : 'company_name' }`]}}

                        components={{
                            Toolbar: CustomToolbar,
                            LoadingOverlay: LinearProgress
                        }}

                        componentsProps={{ toolbar: { } }}

                        localeText={{
                            toolbarFilters: "Filter / Query",
                            toolbarExport: "Download Data"
                        }}


                        getCellClassName={(params) => {
                            //console.log(`${params.field} = ${params.row.key}`)
                            //console.log(params)
                            let myClass = "background-white"

                            if (orientation === false) {
                                let r = allD.filter((item) => item.company_name === params.field && item.data_name === params.row.key)
                                if (r.length >= 1) {
                                    myClass = r[0]["class"]
                                }
                                return myClass
                            } else {
                                //console.log(params)
                                let r = allD.filter((item) => item.company_name === params.row.company_name && item.data_name === params.colDef.headerName)
                                if (r.length >= 1) {
                                    myClass = r[0]["class"]
                                }
                                //console.log(myClass)
                                return myClass
                            }
                        }}

                    >
                    </DataGridPremium>
                </div>
            </div>


            { /* Sidebar */ }
            { /*}
            <div className='sidebar'>
                <div className='sticky-top'>
                    <h5>Analysis Settings</h5>
                    <div>Category</div>
                    <div>Subcategory</div>
                    <Box sx={{ minWidth: 120, padding: '10px 0' }}>
                    <FormControl fullWidth>
                            <Select
                                id="subcategory-select"
                                value={subcategory}
                                onChange={handleChangeSubcategory}
                            >
                                <MenuItem value={'none'} style={{textTransform: 'capitalize'}}>None</MenuItem>
                                {
                                    subList.map((item) => (
                                        <MenuItem value={item}><span style={{textTransform: 'capitalize'}}>{ item.replaceAll("_", " ") }</span></MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120, padding: '10px 0' }}>
                        <div>
                            Orientation <Switch label="Orientation" checked={orientation} onChange={handleOrientation} />
                        </div>
                    </Box>
                </div>
            </div>
            */ }
        </Grid>
    )
}




export const FormatData = (data, filters, orientation) => {
    if (data === undefined) return { rows: [], columns: []}

    console.log(data)

    // Holds an array of all data received before formatting
    let AllData = []
    //let cn = []  // {"companyname": "", "key": "", "class": "classNAME"}

    // Get HoverMap
    let hoverMap = data.map((item) => {
        // get rows and add to rows obj
        let ald = item.data.map((data) => {
            let newObj = {
                "company_name": item.company_name,
                "data_name": data.name,
                hoverText: data.hoverText,
            }
            return newObj
        })

        return ald
    })

    // Contains Hover Data
    const hoverMapData = [].concat(...hoverMap)


    let columns = data.map((item) => {
        // get rows and add to rows obj
        item.data.map((data) => {
            let newObj = {
                "company_name": item.company_name,
                "id": item.company_id,
                key: data.key,
                "data_name": data.name,
                category: data.category,
                subcategory: data.sub_category,
                value: data.display_value, //`${data.value}:${data.class}`
                class: data.class,
                hoverText: data.hoverText
            }

            AllData.push(newObj)

            // add to classname array
            //cn.push({"id": item.company_id, "company_name": item.company_name, "key": data.name, "class": data.class })
            /*
            if (filters !== null && filters !== undefined && filters.category !== 'all') {
                // Category Filter
                if (filters.category !== data.category) {
                    return undefined
                }
            }
            */

            // If it matches push to all data
            return newObj
        })

        //const newD = ald.filter(i => i !== undefined)

        // get class aligned here

        // add column to array
        return { field: item.company_name, headerName: item.company_name, width: 150, "id": item.company_id,
            renderCell: (params) => {
                // get hovermaptext
                const itemL = hoverMapData.filter((item) => item.company_name === params.field && item.data_name === params.row.key)
                return (
                    <Tooltip title={ itemL[0].hoverText.split('|').map(i => <>{i}<br /></>) } >
                        <span className="csutable-cell-trucate">{params.value}</span>
                    </Tooltip>
                )
            }}
    })


    // AllData filtering
    let filteredD = []

    if (filters.category !== 'all') {
        for(let x = 0; x < AllData.length; x++) {
            let item = AllData[x]
            if (filters !== null && filters !== undefined && filters.category !== 'all') {
                // Category Filter
                if (filters.category === item.category) {
                    filteredD.push(item)
                }
            }
        }
    } else {
        filteredD = AllData
    }



    // Add field to columns
    columns = [{field: "key", headerName: "Name", width: 270, id: 0},...columns]

    // Get Unique Subkeys
    let allkeys = filteredD.map(r => r.key).filter((v, i, s) => s.indexOf(v) === i)
    let allCompanies = filteredD.map(r => r.company_name).filter((v, i, s) => s.indexOf(v) === i)

    // Loop All Data to format row data
    let newRows = []
    for(let i = 0; i < allkeys.length; i++) {
        // get key
        let curKey = allkeys[i]

        // Loop each company
        let newObj = { id: i}

        // Make Row Here
        allCompanies.map((indCompany) => {
            newObj[indCompany] = GetValue(filteredD, indCompany, curKey)
            return undefined
        })

        newObj["key"] = PrettyName(filteredD, curKey)


        // Check if Dynamic Rendering
        if (!newRows.includes(newObj)) {
            newRows.push(newObj)
        }
    }


    // Flip Orientation if enabled
    if (orientation === false || orientation === undefined) {    // Normal Onload
        return { rows: newRows, columns: columns, data: filteredD}

    } else {
        return {...FlipOrientation({ data: AllData}, filters, hoverMapData), data: AllData}
    }
}




function RowContainsId(name, rows) {
    for(let y = 0; y < rows.length; y++) {
        if (name === rows[y].company_name) {
            return y
        }
    }
    return undefined
}

function FlipOrientation(data, filters, hoverMapData) {
    //console.log("[ flipping orientation ]")
    // Flip Rows / Column
    let newRows = []
    let newCols = [{
        field: 'company_name',
        headerName: "Company Name",
        width: 270,
    }]

    // Make Columns
    //let newClasses = []
    for (let i = 0; i < data.data.length; i++) {
        let line = data.data[i];
        if (filters !== null && filters !== undefined && filters.category !== 'all') {
            // Category Filter
            if (filters.category !== line.category) {
                continue
            }
        }

        // make columns of subcategories
        //console.log(line)

        let newCol = {
            field: line.key,
            headerName: line.data_name,
            width: 150,
            renderCell: (params) => {
                // get hovermaptext
                const itemL = hoverMapData.filter((item) => item.company_name === params.row.company_name && item.data_name === params.colDef.headerName)
                return (
                    <Tooltip title={ itemL[0].hoverText.split('|').map(i => <>{i}<br /></>) } >
                        <span className="csutable-cell-trucate">{params.value}</span>
                    </Tooltip>
                )
            }
        }

        let testExists = newCols.filter((item) => {
            if (item.headerName === line.data_name){
                return true
            } else {
                return false
            }
        })

        if (testExists.length < 1) {
            //console.log(newCol)
            newCols.push(newCol)
        }

        // Check if row contains and add
        // if not create & push

        let idContained = RowContainsId(line.company_name, newRows)
        if (idContained === undefined) {
            let newRow = {
                id: i,
            }
            newRow["company_name"] = line.company_name
            newRow[line.key] = line.value
            newRows.push(newRow)
        } else {
            newRows[idContained][line.key] = line.value
        }

        //let cn = data.classNames.filter((item) => (item.key === line.data_name) && (line.company_name === item.company_name))[0].class
        //let classObj = {"id": line.company_id, "company_name": line.company_name, "key": line.data_name} //, "class": cn}
        //newClasses.push(classObj)
    }


    //console.log(AllData)


    return {rows: newRows, columns: newCols}
}


// Get Value of Company through cell
function GetValue(AllData, companyName, key) {
    let newFiltered = AllData.filter(item => item.company_name === companyName && key === item.key ? true : false)
    if (newFiltered.length > 0) {
        return newFiltered[0].value
    }
}

// Pretty Name From Key
function PrettyName(AllData, key) {
    let pn = AllData.filter(item => item.key === key ? item.data_name : false)
    return pn[0].data_name
}
