import { Container } from '@mui/material'
import * as React from 'react'
import { UpdateProfile } from '../../components/cognitoauth/views/UpdateProfile'


export const Profile = (props) => {

    return (
        <Container>
            <UpdateProfile />
        </Container>
    )
}