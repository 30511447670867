import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ProjectContext } from '../../../components/contexts/Project';
import { CleanNameForColumnKey, VerticalChart } from './VerticalChart'


export const VerticalCompanyList = (props) => {
    const [ dynRows, setRows ] = React.useState([])
    const [ dynCols, setCols ] = React.useState([])

    const [ selectedCompanies, setSelectedCompanies ] = React.useState([])
    const [ shownCompanies, setShownCompanies ] = React.useState([])
    const [ AllScores, setAllScores ] = React.useState([])


    const ProjC = React.useContext(ProjectContext)

    React.useEffect(() => {
        if (ProjC.selectedCompanyData === undefined || ProjC.selectedCompanyData === null) return
        setSelectedCompanies(ProjC.selectedCompanyData)

        // Extract only name and ID for now on startup
        let newRows = ProjC.selectedCompanyData.map((item) => ({"company_name": item.name, "id": item.id}))
        setRows(newRows)
    }, [ProjC.selectedCompanyData])

    React.useEffect(() => {
        console.log("[ resetting list ]")
        setShownCompanies([])
    }, [ProjC.currentCohort])

    React.useEffect(() => {
        let columns = [
            //{ field: 'id', headerName: 'ID', width: 70 },
            { field: 'company_name', headerName: 'Name', width: 250 },
            //{ field: 'sector', headerName: 'Sector', width: 250 },
            //{ field: 'subgroup', headerName: 'Sub Group', width: 200 },
            //{ field: 'ticker', headerName: 'Ticker', width: 90 },
        ];
        setCols(columns)

    }, [])


    React.useEffect(() => {
        if (selectedCompanies === undefined) return
        // Loop shown companies extract scores and add fields
        let compScores = selectedCompanies.map((company) => {
            let compScore = AllScores.filter((item) => item.company_id === company.id)
            return compScore[0]
        })

        let compScoresFiltered = compScores.filter(i => i !== undefined)
        if (compScoresFiltered === undefined || compScoresFiltered === null) return


        //setRows(ProjC.selectedCompanyData)
        let newMap = compScoresFiltered.map((item) => {
            let rawKeys = Object.keys(item).filter((item) => {
                if ((item !== "company_id") && (item !== "company_name") && (item !== "sector") && (item !== "total_score")) {
                    return item
                }
                return null
            })

            let nonNulls = rawKeys.filter(r => r !== null)
            let newObj = {"company_name": item.company_name, "id": item.company_id }
            // Start Pushing keys to object 
            nonNulls.map((key) => {
                let colKey = CleanNameForColumnKey(key);
                newObj[colKey] = item[key]
                return key
            })
            return newObj
        })
        
        // AllScores brought from chart 
        if (newMap.length !== 0) {
            setRows(newMap)
        }

    }, [AllScores])


    return (
        <div>
            <div  style={{marginBottom: '20px'}}>
                <VerticalChart 
                    allScores={AllScores}
                    selectedCompanies={shownCompanies} 
                    dynRows={dynRows} 
                    setAllScores={setAllScores} 
                    category={props.category} 
                    columns={dynCols} 
                    setRows={setRows} 
                    setColumns={setCols} />
            </div>
            <div style={{ height: 400, width: '100%' }}>

                <DataGrid
                    rows={dynRows}
                    columns={dynCols}
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                        if (ids.length < 4) {
                            setShownCompanies(ids);
                        } 
                        // ids is array of selected
                    }}
                    selectionModel={shownCompanies}
                />
            </div>
        </div>
    );
}
