import * as React from 'react'
import { ProjectContext } from '../../components/contexts/Project'
import { List, ListItem, ListItemButton } from '@mui/material'
import ProjectCompanyList from '../../components/contexts/subviews/ProjectCompanyList'
import { Link } from 'react-router-dom'

export const Cohorts = (props) => {

    const ProjC = React.useContext(ProjectContext)

    const [ cohortList, setCohortList ] = React.useState([])
    const [ selectedCohort, setSelected ] = React.useState()

    // Selection Change 
    const changeCohort = (id) => {
        ProjC.SetCurrentCohort(id) 
    }

    // Onload
    React.useEffect(() => {
        if (ProjC.cohortList !== undefined) {
            setCohortList(ProjC.cohortList)
        }

        if (ProjC.selectedCohort !== undefined) {
            setSelected(ProjC.selectedCohort)
        }
    }, [ProjC.cohortList, ProjC.selectedCohort])

    // Divider Displayed?
    let dividerDisplayed = false
    const CheckDivider = (coh) => {
        if (ProjC.IsDefaultOrNot(coh) && !dividerDisplayed) {
            dividerDisplayed = true 
            return '1px solid #0005'
        } else {
            return null
        }
    }

    return (
        <div className='reportPage' style={{display: 'flex', flexDirection: 'row'}}>
            <div className='reportCard' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <div><h1 className='capitalize underline'>Cohort Management</h1></div>

                <div>
                    <ProjectCompanyList selectedCohort={selectedCohort}  />
                </div>

                { ProjC.selectedCohort !== undefined  ? (
                    <div className='selectedCompanyList'>
                        { /* Companies Selected Here */ }
                        <SelectedCompanies  />
                    </div>
                ): (
                    <div style={{minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <h2 className='header-one' style={{textTransform: 'uppercase'}}>Please select a Cohort to begin</h2>
                    </div>
                )}
 
               
            </div>

            { /* Sidebar */ }
            <div className='sidebar'>
                <div className='sticky-top'>
                    <h5>Cohort Selection</h5>
                    <List>
                        { cohortList.map((item) => {
                            return (
                                <ListItem disablePadding key={item.id} onClick={() => changeCohort(item.id)} style={{borderTop: CheckDivider(item)}}>
                                    <ListItemButton selected={selectedCohort === item.id} >{ item.name }</ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
            </div>
        </div>
    )
}

const SelectedCompanies = (props) => {
    const ProjC = React.useContext(ProjectContext)

    if (ProjC.selectedCompanyData === undefined) return null
    return (
        <div>
            <h2>Selected Companies</h2>
            {
                 ProjC.selectedCompanyData.map((comp) => (
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #0003', minWidth: '400px'}} key={comp.id}>
                        <div>
                            <Link to={process.env.PUBLIC_URL + `/data/company/${comp.id}`} className="destyle" >{ comp.name }</Link>
                        </div>
                        <div>{ comp.sector }</div>
                    </div>
                ))
            }
            
        </div>
    )
}