import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProjectContext } from '../Project';
import { MenuItem, Button, Select, InputLabel, FormControl } from '@mui/material';
import { ViewCohortDialog } from './ViewCohorts'

export const ProjectList = ({ cohortList }) => {
    const ProjC = React.useContext(ProjectContext)

    const DelP = (id) => {
        ProjC.DeleteCohort(id)
        ProjC.SetCurrentCohort(0)
    }

    return (
        <div style={{paddingBottom: '20px'}}>
        { cohortList !== undefined ? (
            <div>
                <CohortSelectionForm  />
                { ProjC.selectedCohort !== undefined && !ProjC.IsDefaultNameOrNot(ProjC.selectedCohort) ? (
                    <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '10px', paddingBottom: '10px'}}>
                        <ViewCohortDialog curCohort={ProjC.selectedCohort} />
                        <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => {DelP(ProjC.selectedCohort)}} color="error">
                            Delete
                        </Button>
                    </div>
                ) : null}

            </div>
        ) : null}
        </div>
    )
}


export const CohortContext = React.createContext()
export const CohortProvider = ({children}) => {
    const ProjC = React.useContext(ProjectContext)
    const [ curId, setId ] = React.useState(ProjC.selectedCohort)

    const changeId = (e) => {
        let newId = e.target.value;
        ProjC.SetCurrentCohort(newId) 
        setId(newId)
    }

    const cohortList = ProjC.cohortList

    React.useEffect(() => {
        setId(ProjC.selectedCohort)
    }, [ProjC.selectedCohort])

    const state = {
        curId,
        changeId,
        cohortList
    }

    return (
        <CohortContext.Provider value={state}>{ children}</CohortContext.Provider>
    )
}

export const CohortSelectionForm = () => {
    // Cohort Context
    const cohortC = React.useContext(CohortContext)
    const ProjC = React.useContext(ProjectContext)
    const [ localId, setLocal ] = React.useState(cohortC.curId)

    const handleChange = (e) => {
        cohortC.changeId(e)
        setLocal(e.target.value)
    }



    return (
        <CohortProvider>
        <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Cohort</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localId}
                label="Cohort"
                onChange={handleChange}
            >
                
                { cohortC.cohortList.map((proj) => {
                    // Dont Render within Edit Menu if default
                    if (ProjC.IsDefaultOrNot(proj)) return null

                    return (
                        <MenuItem value={proj.id}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>{ proj.name }</div>
                            </div>
                        </MenuItem>
                    )
                })}

            </Select>
        </FormControl>
        </CohortProvider>
    )
}


