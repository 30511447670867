import * as React from 'react'
import { Snackbar, Alert } from '@mui/material'

export const StatusContext = React.createContext()

export const StatusProvider = ({ children }) => {
    const [ statusMessage, setStatusMessage ] = React.useState({})
    const [ displayed, setDisplayed ] = React.useState(false)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setDisplayed(false);
    };

    // type:  warning, error, info, success 

    const DisplayMessage = async (message, type, duration) => {
        setStatusMessage({message: message, type: type, duration: duration})
        setDisplayed(true)
    }

    const state = {
        DisplayMessage,
    }
    
    return (
        <StatusContext.Provider value={state}>
            { children }
            <Snackbar
                open={displayed}
                autoHideDuration={statusMessage.duration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={statusMessage.type} sx={{ width: '100%' }}>
                    { statusMessage.message }
                </Alert>
            </Snackbar>
        </StatusContext.Provider>
    )
}


/* 
    const testStatus = React.useContext(StatusContext)
  
    // Test Auth
    React.useEffect(() => {
      testStatus.DisplayMessage("Test Message", "error")
    }, [])



*/