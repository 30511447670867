import * as React from 'react'
import { Box, FormControl, Select, MenuItem } from "@mui/material"
import { VerticalCompanyList } from './components/VerticalCompanyList';
//import { ProjectContext } from '../../components/contexts/Project';
import { APIContext } from '../../components/api/Api';
import { ManageProjectsToolbar } from '../../components/contexts/Project';

export const Vertical = (props) => {
    const [ category, setCategory] = React.useState("People");

    const [ allCategories, setAllCategories] = React.useState([])

    //const ProjC = React.useContext(ProjectContext)
    const ApiC = React.useContext(APIContext)


    /////////////////////////////////////////////////////////////////////
    /* Selections */
    /////////////////////////////////////////////////////////////////////
    const handleCategoryChange = (e) => {
        setCategory(e.target.value)
    }

    /////////////////////////////////////////////////////////////////////
    // Get Categories
    /////////////////////////////////////////////////////////////////////
    React.useEffect(() => {
        ApiC.DataCategoryList().then((cats) => {
            setAllCategories(cats)
            setCategory(cats[0])
        })

    }, [ApiC])

    /////////////////////////////////////////////////////////////////////


    return (
        <div className='reportPage' style={{display: 'flex', flexDirection: 'row'}}>
            <div className='reportCard' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <div className='reportToolbar' style={{display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center'}}>
                    <ManageProjectsToolbar />
                </div>

                <div><h1 className='capitalize underline'>{ category !== undefined ? category.name : null } Drill Down </h1></div>
                { /* Render List Selection */ }
                <div style={{marginTop: '30px'}}>
                    <VerticalCompanyList category={category}  />
                </div>
            </div>

            { /* Sidebar */ }
            <div className='sidebar'>
                <div className='sticky-top'>
                    <h5>Analysis Settings</h5>

                    { /* @Justin - the following three should contain the companies from the "active" cohort.
                        Selecting a company updates the vertical graphs and list of other companies below the graph.
                        Also below each graph should be a table of the not-selected cohorts and thier scores
                    */ }

                    <div>Category</div>
                    <Box sx={{ minWidth: 120, padding: '10px 0' }}>
                        <FormControl fullWidth>
                            <Select
                                id="category-select"
                                value={category}
                                onChange={handleCategoryChange}
                            >
                                {
                                    allCategories.map((cat) => {
                                        return (<MenuItem value={cat} key={cat.name}>{ cat.name }</MenuItem>)
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>

                </div>
            </div>
        </div>
    )
}

