import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ProjectContext } from '../Project';


export function ViewCohortDialog(props) {
    const [ open, setOpen ] = React.useState(false);
    const handleClickOpen = async () => setOpen(true)
    const handleClose = async () => setOpen(false)

    const ProjC = React.useContext(ProjectContext)
    // Get List

    return (
        <div>
            <div>
                <Button variant="outlined" onClick={handleClickOpen}>
                    View Company List
                </Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Current Cohort Companies</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { /* The list of currently selected companies for the cohort is below. */ }
                    </DialogContentText>
                    <div>
                        { ProjC.selectedCompanyData !== undefined ? (
                            <div style={{padding: '10px', maxHeight: '500px', overflowY: 'scroll'}}>
                                { ProjC.selectedCompanyData.map((comp) => (
                                    <div key={comp.id} style={{display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #0003', minWidth: '400px'}}>
                                        <div>{ comp.name }</div>
                                        <div>{ comp.sector }</div>
                                    </div>
                                ))}
                            </div> 
                        ) : null }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
  );
}
