import * as React from 'react'
import { Grid, TextField, Button, Alert } from "@mui/material"

import { AuthContext } from '../auth/Auth';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';

export const LoginForm = (props) => {
    const [ username, setUsername] = React.useState('')
    const [ password, setPassword] = React.useState('')
    const [ redirect, setRedirect] = React.useState(null)

    const [ errorMessage, setErrorMessage] = React.useState(null)

    const changeUsername = (e) => setUsername(e.target.value)
    const changePassword = (e) => setPassword(e.target.value)
    const authContext = React.useContext(AuthContext) 
    
    const login = () => {
        const LoginFunc = async () => {
            try {
                await authContext.signInWithEmail(username, password)
                // True show email code box 
                setErrorMessage(null)

                // Signed In Actions Here
                setRedirect(true)
            } catch (err) {
                // False
                setErrorMessage(`${err}`)
            }
        }
        LoginFunc()
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6} style={{flexDirection: 'column', display: 'flex'}}>
                    <h1>Login</h1>
                    <TextField className="mb-10" id="username" value={username} label="Username" variant="outlined" onChange={changeUsername} />
                    <TextField className="mb-10" id="password" type="password" value={password} label="Password" variant="outlined" onChange={changePassword} />
                    <Button variant="contained" onClick={login}>Login</Button>

                    <div style={{padding: '20px', textAlign: 'center'}}>
                        <Link to="/forgotpassword">Forgot Password</Link>
                    </div>
                </Grid>
                <Grid item xs={0} md={3} />
            </Grid>


            { errorMessage !== null ? (
                <Alert severity="error">{ errorMessage }</Alert>
            ) : null }

            
            { redirect !== null ? (
                <Navigate to='/data/explore' />
            ) : null}
        </div>
    )
}
