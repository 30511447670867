import * as React from 'react'
import {  Grid, TextField, Button, Alert } from "@mui/material"
import { AuthContext } from '../../auth/Auth';

export const UpdatePassword = (props) => {
    // change password 
    const [ oldPassword, setOldPassword] = React.useState('')
    const [ newPassword, setNewPassword] = React.useState('')

    const [ errorMessage, setErrorMessage] = React.useState(null)
    const [ successMessage, setSuccessMessage] = React.useState(null)

    const changeOldPassword = (e) => setOldPassword(e.target.value)
    const changeNewPassword = (e) => setNewPassword(e.target.value)
    const authContext = React.useContext(AuthContext) 

    const [ confirmPassword, setConfirmPassword ] = React.useState('')
    const [ passwordMatch, setPasswordMatch] = React.useState(false)
    const changeConfirm = (e) => { setConfirmPassword(e.target.value) }
    React.useEffect(() => {
        const checkMatch = () => {
            if (confirmPassword === newPassword && (newPassword !== '')) {
                setPasswordMatch(true)
            } else {
                setPasswordMatch(false)
            }
        }
        checkMatch()
    }, [confirmPassword, newPassword])

    const change = () => {
        const ChangePassword = async () => {
            try {
                await authContext.changePassword(oldPassword, newPassword)
                // True show email code box 

                setSuccessMessage(`Password Successfully Changed`)
                setErrorMessage(null)

            } catch (err) {
                // False
                setErrorMessage(`${err}`)
                setSuccessMessage(null)
            }
        }

        if (passwordMatch) {
            ChangePassword()
        }
    }

    return (
        <div style={{padding: '20px'}}>
            
            <Grid container>
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6} style={{flexDirection: 'column', display: 'flex'}}>
                    <h1>Update Password</h1>
                    <TextField className="mb-10" id="oldPassword" type="password" value={oldPassword} label="Old Password" variant="outlined" onChange={changeOldPassword} />
      
                    <div style={{paddingBottom: '20px'}}>
                        <div>
                            <TextField className="mb-10" style={{display: 'flex'}} id="password" type="password" value={newPassword} label="New Password" variant="outlined" onChange={changeNewPassword} />
                        </div>
                        <div>
                            <TextField className="mb-10" style={{display: 'flex'}} id="confirmpassword" type="password" value={confirmPassword} label="Confirm Password" variant="outlined" onChange={changeConfirm} />
                        </div>
                        <div>{ passwordMatch === true ? <></> : <Alert severity="error">Passwords Do Not Match</Alert> }</div>
                    </div>

                    <Button variant="contained" onClick={change}>Change Password</Button>
                </Grid>
                <Grid item xs={0} md={3} />
                
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6} style={{paddingTop: '20px'}}>
                    { errorMessage !== null ? (
                        <Alert severity="error">{ errorMessage }</Alert>
                    ) : null }

                    { successMessage !== null ? (
                        <Alert severity="success">{ successMessage }</Alert>
                    ) : null }
                </Grid>
                <Grid item xs={0} md={3} />
            </Grid>
        </div>
    )
}