import * as React from 'react'
import {  Grid, TextField, Button, Alert } from "@mui/material"
//import { AuthContext } from '../../auth/Auth';

export const UpdateEmail = (props) => {
    // change password 
    const [ email, setEmail] = React.useState('')

    const [ errorMessage, setErrorMessage] = React.useState(null)
    const [ successMessage, setSuccessMessage] = React.useState(null)

    const changeEmail = (e) => setEmail(e.target.value)

    const change = () => {
        setSuccessMessage('Changed Email')
        setErrorMessage('Error')
    }

    return (
        <div style={{padding: '20px'}}>
      
            <Grid container>
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6} style={{flexDirection: 'column', display: 'flex'}}>
                    <h1>Update Email - NoOp</h1>
                    <TextField className="mb-10" id="email" value={email} label="Email" variant="outlined" onChange={changeEmail} />
                    <Button variant="contained" onClick={change} >Change Email</Button>
                </Grid>
                <Grid item xs={0} md={3} />

                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6} style={{paddingTop: '20px'}}>
                    { errorMessage !== null ? (
                        <Alert severity="error">{ errorMessage }</Alert>
                    ) : null }

                    { successMessage !== null ? (
                        <Alert severity="success">{ successMessage }</Alert>
                    ) : null }
                </Grid>
                <Grid item xs={0} md={3} />
            </Grid>
        </div>
    )
}