import * as React from 'react'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProjectCompanyList from './subviews/ProjectCompanyList';
import { ProjectContext } from './Project';
import AddProjectDialog from './subviews/AddProjectDialog'
import { CohortContext, CohortProvider, ProjectList } from './subviews/ProjectList';
import {Settings} from '@mui/icons-material'

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

export const ManageProjectsDialog = (props) => {

    const [ open, setOpen] = React.useState(false);
    const [ name, setName ] = React.useState('')

    const ProjC = React.useContext(ProjectContext)

    const updateName = (e) => { setName(e.target.value)}
    const AddCohort = () => {
        ProjC.AddCohort(name)
        setName('')
    }
    const handleClickOpen = () => { setOpen(true);};
    const handleClose = () => { setOpen(false); };

    const currentCohort = ProjC.GetCurrentCohort()

    return (

        <CohortProvider>

            <div className='cohortBarCont'>
                <div className='cohortBar' >
                    <div className='curCohort'>
                        <RenderDropDownList currentCohort={currentCohort} />
                    </div>
                    <div>

                        <Button
                            className={'stacked-text-label'}
                            variant="text"
                            startIcon={<Settings />}
                            onClick={handleClickOpen}
                        ><span>Manage<br/>Cohorts</span></Button>

                    </div>
                </div>
            </div>

            <Dialog
                scroll="body"
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                {"Cohort Management"}
                </DialogTitle>
                <DialogContent>
                { /* Projects if More than none */}
                <div style={{display: 'flex', justifyContent: 'space-around'}} >
                    <AddProjectDialog AddCohort={AddCohort} updateName={updateName} name={name} />

                </div>


                <div style={{paddingTop: '20px'}}>
                    <DialogContentText style={{paddingBottom: '15px'}}>
                        Select cohort to make active and/or edit
                    </DialogContentText>

                    <ProjectList cohortList={ProjC.cohortList} />
                    <ProjectCompanyList selectedCohort={props.selectedCohort} closeDialog={handleClose} />
                </div>


                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} autoFocus variant="contained" color="success">
                    Save
                </Button>
                </DialogActions>
            </Dialog>
        </CohortProvider>
    );
}

const RenderDropDownList = ({currentCohort}) => {
    //const [ dividerDisplayed, setDividerDisplayed ] = React.useState
    const ProjC = React.useContext(ProjectContext)
    let dividerDisplayed = false
    const CheckDivider = (coh) => {
        if (ProjC.IsDefaultOrNot(coh) && !dividerDisplayed) {
            dividerDisplayed = true
            return '1px solid #0005'
        } else {
            return null
        }
    }

    const cohortC = React.useContext(CohortContext)
    const staticVal = currentCohort !== undefined && currentCohort !== null ? currentCohort.id : ''

    return (
        <div>
            <div>
                { cohortC.cohortList !== undefined ? (
                    <FormControl fullWidth style={{minWidth:'300px', maxWidth: '300px'}}>
                    <InputLabel id="demo-simple-select-label">Cohort</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={staticVal}
                            label="Cohort"
                            onChange={cohortC.changeId}
                        >


                            {
                                cohortC.cohortList.map((proj) => {
                                    return (
                                        <MenuItem value={proj.id} key={proj.id} style={{
                                                borderTop: CheckDivider(proj)

                                            }}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <div>{ proj.name }</div>
                                            </div>
                                        </MenuItem>
                                    )
                                })
                            }

                        </Select>
                    </FormControl>
                ): null}

            </div>
        </div>
    )
}
