import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function AddProjectDialog({ AddCohort, updateName, name }) {
    const [ open, setOpen ] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const AddCloseFunc = () => {
        AddCohort()
        handleClose()
    }

    return (
        <div>
            <div>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Create Cohort
                </Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create Cohort</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Please give the new cohort a name:
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Cohort Name"
                    type="text"
                    value={name}
                    onChange={updateName}
                    fullWidth
                    variant="standard"
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => AddCloseFunc()}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
  );
}
