import * as React from 'react'
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from '@mui/material';
import { CompanyDetailed } from '../CompanyDetailed';
import { ExploratoryDetailed } from '../ExploratoryDetailed';
import { Vertical } from '../Vertical';
import { Explore } from '../Explore';
import {PictureAsPdf} from "@mui/icons-material";

const printRef = React.createRef()

const PDFCreate = ({children}) => {
    return (
        <div>
            <div ref={printRef}>
                { children }
            </div>
        </div>
    )
}

export const FileDownloadButton = (props) => {

    const exportPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('SocialMarksReport.pdf');
    }

    <isNotDemo>
    return (
        <Button
            variant="text"
            startIcon={<PictureAsPdf />}
            size="small"
            onClick={ exportPdf }>Export PDF</Button>
    )
    </isNotDemo>
}

export const CompanyDetailedPDF = (props) => {
    return (
        <PDFCreate>
            <CompanyDetailed />
        </PDFCreate>
    )
}

export const BreakDownPDF = (props) => {
    return (
        <PDFCreate>
            <ExploratoryDetailed />
        </PDFCreate>
    )
}

export const VerticalPDF = (props) => {
    return (
        <PDFCreate>
            <Vertical />
        </PDFCreate>
    )
}

export const ExplorePDF = (props) => {
    return (
        <PDFCreate>
            <Explore />
        </PDFCreate>
    )
}
