
import * as React from 'react'
import {Button, Grid, ListItem, ListItemText} from '@mui/material'
import { Container } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogContentText, DialogActions} from '@mui/material'
import { APIContext } from '../../../components/api/Api';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {AddCircle} from "@mui/icons-material";
import { List } from '@mui/material'

export const FileManagement = (props) => {
    const [ fileList, setFileList ] = React.useState([])
    const [ processingList, setProcessingList ] = React.useState([])
    const [ activeFile, setActiveFile ] = React.useState(undefined)

    // File Upload
    const [ fileData, setFileData] = React.useState()

    // Pagination
    const [ page, setPage ] = React.useState(1)
    const increasePage = async () => { setPage(page+1) }
    const decreasePage = async () => { if (page !== 1) { setPage(page-1) }}
    const [ perPage, setPerPage ] = React.useState(20)
    const handlePerPageChange = async (e) => { setPerPage(e.target.value)}

    const ApiC = React.useContext(APIContext)


    // onload
    React.useEffect(() => {
        // API Call to get file list
        ApiC.ListFilesUploaded(page, perPage).then(res => {
            if (res["data"].length > 0) {
                setFileList(res["data"])
            }
        })

        // Processing List
        ApiC.ListFilesUploaded(page, perPage, "processing").then(res => {
            if (res["data"].length > 0) {
                setProcessingList(res["data"])
            }
            console.log(res["data"])
        })

    }, [ApiC, page, perPage])

    // List Processing



    // Handle File Change
    const HandleChange = async (e) => {
        setFileData(e.target.files[0])
    }

    React.useEffect(() => {
        // Upload File
        const UploadFile = async () => {
            if (fileData === undefined) return

            // set base for api
            // const AdminUpload = async (type, rerun_duplicate, file)
            let type = "demodata"
            let rerun_duplicate = true
            let file = fileData
            console.log(file)

            ApiC.AdminUpload(type, rerun_duplicate, file).then(res => {
                // Upload result
                console.log("[ upload result ]")
                console.log(res)
            })
        }

        // on file data change attempt to upload
        UploadFile()
    }, [ApiC, fileData])

    return (
        <Container className={'adminFluid'}>
            <Grid container>
                <Grid item xs={6}>
                    <div className='admin-box-header'>
                        <h1>File Management</h1>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '20px'}}>
                        <Button
                            variant="text"
                            component="label"
                            startIcon={<AddCircle />}
                        >
                            Upload File
                            <input hidden accept="*" multiple type="file" onChange={HandleChange} />
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} className='admin-box'>

                    <div className='admin-box-body'>
                        <div className='fileList'>
                            <List component="nav" aria-label="main mailbox folders" className={'list-hover'}>
                                { fileList.map((file) => {
                                    return (
                                        <ListItem
                                            key={file.id}
                                            secondaryAction={
                                                <Button variant='text' onClick={() => setActiveFile(file)}>View</Button>
                                            }
                                        >
                                            <ListItemText
                                                primary={ (file.id ? file.id : 'ID') + " : "+ (file.filename ? file.filename : 'FileName') }
                                                secondary={ file.cloud_path}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                backgroundColor: '#FFFFFF',
                                padding: '1rem'
                            }}
                        >

                            { /* Pagination */}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            }}>
                                <Button
                                    onClick={decreasePage}
                                    variant="outlined"
                                    disabled={page === 1 ? true : false}
                                    style={{paddingTop: '10px', paddingBottom: '10px'}}
                                    startIcon={<ArrowBackIosNewIcon />}
                                > Previous </Button>

                                <Button
                                    onClick={increasePage}
                                    variant="outlined"
                                    style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        marginLeft: '10px',
                                        marginRight: '10px'
                                    }}
                                    endIcon={<ArrowForwardIosIcon />}
                                > Next </Button>

                                <div>
                                    Current Page: { page }
                                </div>
                            </div>

                            { /* Per Page */}
                            <FormControl style={{width: '120px'}}>
                                <InputLabel id="demo-simple-select-label">Per Page</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={perPage}
                                    label="Per Page"
                                    onChange={handlePerPageChange}
                                >
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} md={12}>
                    <FileInfo activeFile={activeFile} />
                </Grid>

                <Grid item xs={12} md={12} className='admin-box'>
                    <h3>Processing Results</h3>

                    <div className='fileList'>
                        { processingList.map(file => {
                            return (
                                <div style={{borderBottom: '1px solid #0002', padding: '20px'}}>
                                    <div >
                                        { file.id ? file.id : 'File ID'}.  { file.filename ? file.filename : 'FileName' }
                                    </div>
                                    <div>
                                        { file.cloud_path}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </Grid>

                <Grid item xs={12} md={12} className='admin-box'>
                    <h3>Show attached projects</h3>
                </Grid>
            </Grid>
        </Container>
    )
}

const FileInfo = ({activeFile}) => {
    const [open, setOpen] = React.useState(false);
    const [activeLocal, setActiveLocal] = React.useState()


    React.useEffect(() => {
        if (activeFile === undefined) return
        setOpen(true);
        setActiveLocal(activeFile)
    }, [activeFile])

    const handleClose = () => {
        setOpen(false);
    };

    // No Project Selected
    if (activeLocal === undefined) return

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {`File Management`}
          </DialogTitle>
          <DialogContent sx={{minWidth: '400px'}}>
            <DialogContentText>
                <div>
                    Name: { activeLocal.filename }
                </div>
                <div>
                    Cloud Path: { activeLocal.cloud_path}
                </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}
