import * as React from 'react';
import {Button, Container, Grid, TextField, Modal, Box, Typography, ListItem, ListItemText} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AddCircle} from "@mui/icons-material";
import { List } from '@mui/material'

class Project {
    constructor(name) {
        this.name = name;
    }
}

var tempProjects = [
    new Project("Project One"),
    new Project("Project Two"),
    new Project("Project Three"),
    new Project("Project Four"),
]

export const ProjectHome = (props) => {
    const [ allProjects, setAllProjects ] = React.useState([])

    React.useEffect(() => {
        // API CALL TO GET LIST OF PROJECTS
        setAllProjects(tempProjects)
    }, [])


    // create project
    const createProject = async (name) => {
        // API CALL TO CREATE
        console.log(`[ create project ${name} ]`)
        let newProj = new Project(name)
        setAllProjects([...allProjects, newProj])

    }

    /*
    // delete project
    const deleteProject = async(name) => {
        // API CALL TO DELETE PROJECT
        console.log(`[ delete project ${name}]`)
    }
    */

    return (
        <Container className={'adminFluid'}>
            <Grid container>
                <Grid item xs={6}>
                    <div className='admin-box-header'>
                        <h1>Active Projects</h1>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <CreateProject createProject={createProject}/>
                </Grid>
                <Grid item xs={12} className='admin-box'>
                    <List component="nav" aria-label="main mailbox folders" className={'list-hover'}>
                        { allProjects.map((proj) => (
                            <ListItem
                                key={proj.id}
                                secondaryAction={
                                    <ProjectModal project={proj} />
                                }
                            >
                                <ListItemText primary={ proj.name } />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Container>

        /*

        <Grid container spacing={2} style={{maxHeight: '400px', overflowY: 'scroll', padding: '20px'}}>
            { allProjects.map((proj) => (
                <Grid item xs={12} md={4}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '15px',
                        marginBottom: '15px',
                        backgroundColor: '#eee8',
                        boxShadow: '1px 1px 2px 2px #0003'
                    }}>
                        <div style={{padding: '5px'}}>
                            <h3>{ proj.name }</h3>
                        </div>
                        <div >
                            <div>
                                <Button variant='outlined' onClick={() => {setSelectedProject( proj.name)}}>

                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>

        <Grid item xs={12} md={12}>
            <SelectedProject selected={selectedProject} />
        </Grid>


        <div className='admin-box'>
            <div className='admin-box-header'>
                <h3>Data Upload</h3>
                <div className='admin-box-menu'>
                    <Button variant="contained" component="label">
                        Upload
                        <input hidden accept="image/*" multiple type="file" />
                    </Button>
                </div>
            </div>
        </div>
        */

    )
}


const CreateProject = ({ createProject }) => {
    const [open, setOpen] = React.useState(false);

    // create name
    const [ name, setName ] = React.useState('')
    const handleChangeName = async (e) => setName(e.target.value)

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCreate = () => {
        if (name !== '' ) {
            createProject(name)
            setName('')
            setOpen(false);
        }
    };

    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'flex-end', padding: '20px'}}>
            <Button
                variant="text"
                startIcon={<AddCircle />}
                onClick={handleClickOpen}
            >New Project</Button>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Create a new project"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
                Please type in the name of the project and click create
                <TextField onChange={handleChangeName} value={name} fullWidth style={{marginTop: '20px'}}></TextField>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreate} variant="outlined" autoFocus>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

/*
const SelectedProject = ({selected}) => {
    if (selected === undefined) return (
        <div style={{display: 'flex', justifyContent: 'center'}} className='admin-box'>
            <h3>Please Select a Project for Details</h3>
        </div>
    )

    return (
        <div className='admin-box'>
            <h3>Selected Project</h3>
            <div>
                { selected }
                <div>Project Details go here</div>
            </div>
        </div>
    )
}
*/

const ModelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: 400,
}

const ProjectModal = ({project}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
        <Button onClick={handleOpen} variant="text">Details</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModelStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Project
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              { project.name }
            </Typography>
          </Box>
        </Modal>
      </div>
    )
}
