import * as React from 'react'
import { Grid, Box, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Slider } from "@mui/material"
import { useParams } from 'react-router-dom';
import { APIContext } from '../../components/api/Api';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import { IsNotGuest, IsGuest, AuthContext } from '../../components/cognitoauth/auth/Auth';
import { FormatData } from './ExploratoryDetailed';


LicenseInfo.setLicenseKey('91ead7de2936caf6cbb60f548b00ac4aTz00ODIyNixFPTE2OTA3Mzg5OTE5MDMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');



export const CompanyDetailed = (props) => {
    const [ companyData, setCompanyData ] = React.useState()

    // Analysis Settings
    //const [ selectedCompany, setSelectedCompany ] =  React.useState("")
    //const [ categoryFilter, setCategoryFilter] = React.useState("all")
    //const [ subcategoryFilter, setSubCategoryFilter] = React.useState("all")
    //const [ kpiFilter, setKPIFilter] = React.useState("all")

    //const handleChangeCompany = (e) => setSelectedCompany(e.target.value)
    //const handleChangeCategory = (e) => setCategoryFilter(e.target.value)
    //const handleChangeSubcategory = (e) => setSubCategoryFilter(e.target.value)
    //const handleChangeKpi = (e) => setKPIFilter(e.target.value)
    // NEW STATES



    const [ colData, setColData ] = React.useState([])
    const [ rowData, setRowData ] = React.useState([])
    const [ allD, setAllD ] = React.useState([])


    const ApiC = React.useContext(APIContext)
    const AuthC = React.useContext(AuthContext)


    let { companyId } = useParams();
    React.useEffect(() => {
        // Figure guest and endpoint
        if (AuthC.isGuest === true) {
            ApiC.GetCompanyDetailByIdGuest(companyId).then((res) => {
                setCompanyData(res)
    
                // create object to pass to formatdata
                
                // format data 
                let lineObj = FormatData([res.details[0]], {category: 'all'}, false)
                console.log("lineObj", lineObj)
                //setClassNames(lineObj.classNames)
                //console.log(lineObj)
                setAllD(lineObj.data)
                setRowData(lineObj.rows)
                lineObj.columns[1].width = 300
                setColData(lineObj.columns)
            })
        } else {
            ApiC.GetCompanyDetailById(companyId).then((res) => {
                setCompanyData(res)
    
                // create object to pass to formatdata
                
                // format data 
                let lineObj = FormatData([res.details[0]], {category: 'all'}, false)
                console.log("lineObj", lineObj)
                //setClassNames(lineObj.classNames)
                //console.log(lineObj)
                setAllD(lineObj.data)
                setRowData(lineObj.rows)
                lineObj.columns[1].width = 300
                setColData(lineObj.columns)
            })    
        }

        
    }, [ApiC, companyId, AuthC.isGuest])
    

    React.useEffect(() => {
        if (companyData === undefined) return
        console.log("[ loaded data ]")

    }, [companyData])


    const LoadedOrNot = (x) => {
        if ((x === undefined) || (companyData === undefined))return ''
        return x
    }

    


    return (
        <div  className='reportPage' style={{display: 'flex', flexDirection: 'row'}}>
            <div className='reportCard' style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <div><h1>Company Deep Dive / <span style={{fontWeight: 'normal'}}>{ companyData !== undefined ? companyData.name : ''}</span></h1></div>
                <div className='blue-card'>

                    { /* New Company Data */ }
                    <Grid container spacing={2} sx={{padding: '1rem 1rem 2rem', paddingBottom: '300px'}}>
                        <Grid item xs={12} md={6}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: '1'}}>
                                <div style={{display: 'flex', paddingTop: '40px'}}>
                                    { /* SxS boxes */ }
                                    <div style={{textAlign: 'right', paddingRight: '15px'}}>
                                        <div className='detailedInfoLeft'>Headquarters</div>
                                        <div className='detailedInfoLeft'>Founded</div>
                                        <div className='detailedInfoLeft'>CEO</div>
                                        <div className='detailedInfoLeft'>Global Revenue</div>
                                        <div className='detailedInfoLeft'>Global Employees</div>
                                    </div>
                                    <div>
                                        { ((companyData !== undefined) && (companyData.info !== undefined)) ? (
                                            <div>
                                                <div className='detailedInfoRight'>{ LoadedOrNot(companyData.info.headquarters) }</div>
                                                <div className='detailedInfoRight'>{ LoadedOrNot(companyData.info.founded) }</div>
                                                <div className='detailedInfoRight'>{ LoadedOrNot(companyData.info.ceo) }</div>
                                                <div className='detailedInfoRight'>{ LoadedOrNot(companyData.info.globalrevenue) }</div>
                                                <div className='detailedInfoRight'>{ LoadedOrNot(companyData.info.globalemployees) }</div>
                                            </div>
                                        ) : (<div>Loading</div>)}
                                    </div>
                                </div>
         
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6} style={{paddingBottom: '50px'}}>
                            <h2 className='uppercase vcenter-line-end'><span>Score</span></h2>
                            <div>Key Performance Indicators</div>

                            { ((companyData !== undefined) && (companyData.data !== undefined)) ? (
                                <Grid container spacing={2} style={{paddingTop: '30px'}}>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <div className='circle-stat background-light-blue'>
                                                <h3>{ LoadedOrNot(Math.round(companyData.data.total_score))}</h3>
                                                <div>Latest Total Score</div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <div className='circle-stat background-salmon-1'>
                                                <h3>{ LoadedOrNot(Math.round(companyData.data.yoy))}</h3>
                                                <div>YoYGrowth</div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <div className='circle-stat background-blue'>
                                                <h3>{ LoadedOrNot(Math.round(companyData.data.avgyearly))}</h3>
                                                <div>Avg. Yearly Score</div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            ) : null}


                        </Grid>

                        <Grid item xs={12} md={6}>
                            <h2 className='uppercase vcenter-line-end'><span className='text-light-blue'>Ranking</span><span> Position</span></h2>
                            <div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='text-light-blue'>OVERALL RANK</TableCell>
                                                <TableCell className='text-light-blue'>COMPANY</TableCell>
                                                <TableCell className='text-light-blue text-align-right'>SCORE</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        
                                        { /* Ranking Position Table */}
                                        { companyData !== undefined && companyData.position !== undefined ? (
                                            <TableBody>
                                            { companyData.position.map((item) => (
                                                <TableRow key={item.company}>
                                                    <TableCell className={`text-white table-cell-detailed ${item.highlighy === true ? 'activeText': null}`}>{item.rank}</TableCell>
                                                    <TableCell className={`text-white table-cell-detailed ${item.highlighy === true ? 'activeText': null}`}>{item.company}</TableCell>
                                                    <TableCell className={`text-white table-cell-detailed ${item.highlighy === true ? 'activeText': null}`}>{item.score}</TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        ) : null}
                       

                                    </Table>
                                </TableContainer>
                                { /* RANKING POSITION */}
                            </div>
                        </Grid>

                        { companyData !== undefined && companyData.ranking !== undefined ? (
                            <Grid item xs={12} md={6}>
                                <h2 className='uppercase vcenter-line-end'><span className='text-light-blue'>Ranking</span><span> Breakdown</span></h2>
                                <div style={{padding: '10px', paddingTop: '20px'}} className="sliders">
                                    { /* RANKING BREAKDOWN */}
                                    <div style={{padding: '10px'}}>
                                        <div className='text-light-blue uppercase'>People</div>
                                        <Slider value={Math.round(companyData.ranking.people)} max={companyData.ranking.people_max} aria-label="Default" valueLabelDisplay="on" disabled={true}/>
                                    </div>
                                    <div style={{padding: '10px'}}>
                                        <div className='text-light-blue uppercase'>Brand</div>
                                        <Slider value={Math.round(companyData.ranking.brand)} max={companyData.ranking.brand_max} aria-label="Default" valueLabelDisplay="on" disabled={true} />
                                    </div>
                                    <div style={{padding: '10px'}}>
                                        <div className='text-light-blue uppercase'>Community</div>
                                        <Slider value={Math.round(companyData.ranking.community)} max={companyData.ranking.community_max} aria-label="Default" valueLabelDisplay="on" disabled={true} />
                                    </div>
                                </div>
                            </Grid>
                        ):null}
                    </Grid>


                </div>

                <IsNotGuest>
                <h2 className='vcenter-line-end'><span>Score Breakdown</span></h2>
                <Box sx={{ height: 1000, width: '100%' }}>
                <DataGridPremium
                    rowHeight={38}
                    rows={rowData}
                    columns={colData}
                    disableSelectionOnClick

                    getCellClassName={(params) => {
                        //console.log(`${params.field} = ${params.row.key}`)
                        //console.log(params)
                        let myClass = "background-white"
                        let r = allD.filter((item) => item.company_name === params.field && item.data_name === params.row.key)
                        if (r.length >= 1) {
                            myClass = r[0]["class"]
                        } 
                        return myClass 
                    }}


                />
                </Box>
                </IsNotGuest>
                <IsGuest>
                    <img src={process.env.PUBLIC_URL + '/img/blurred.png'} alt="Data Table" style={{maxWidth: '100%', marginTop: '40px'}} />
                </IsGuest>

                { /* Detailed Data View Below */ }
                { /*<Section data={detailedData} categoryFilter={categoryFilter} subCategoryFilter={subcategoryFilter} kpiFilter={kpiFilter} /> */ }
            </div>
        </div>
    )
}
