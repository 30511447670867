import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router,
  Routes,
  Route } from "react-router-dom"
import React from 'react';
import { Navbar } from './components/Navbar.js'
import { Footer } from './components/Footer.js'

import { Register } from './pages/auth/Register.js';
import { Login } from './pages/auth/Login.js';
//import { PasswordReset } from './pages/auth/PasswordReset.js';
//import { UserHome } from './pages/auth/UserHome.js';
//import { TechSupport } from './pages/auth/TechSupport.js';
import { Account } from './pages/auth/Account.js';
//import { Invite } from './pages/auth/Invite.js';
//import { AdminLogin } from './pages/admin/AdminLogin';
import { AdminHome } from './pages/admin/AdminHome';
//import { UserAdmin } from './pages/admin/UserAdmin';
//import { FileManagement } from './pages/admin/FileManagement';
//import { FileProcessing } from './pages/admin/FileProcessing';
//import { AdminProjectHome } from './pages/admin/ProjectHome';
//import { AdminProjectCreate } from './pages/admin/ProjectCreate';
//import { AdminProjectActive } from './pages/admin/ProjectActive';
//import { DataUpload } from './pages/admin/DataUpload';
//import { DataEntry } from './pages/admin/adminviews/subviews/DataEntry';
import { Cohorts } from './pages/auth/Cohorts'
import { IFrameExplore } from './pages/data/Explore';
//import { Explore } from './pages/data/Explore';
//import { Vertical } from './pages/data/Vertical';
//import { ExploratoryDetailed } from './pages/data/ExploratoryDetailed';
//import { CompanyDetailed } from './pages/data/CompanyDetailed';
import { Forecasting } from './pages/data/Forecasting';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import AuthProvider, { IsNotGuest } from './components/cognitoauth/auth/Auth'
import { Logout } from './pages/auth/Logout';
import { Profile } from './pages/auth/Profile';
import { APIProvider } from './components/api/Api';
import { ProjectProviderNew } from './components/contexts/Project';
import { StatusProvider } from './components/contexts/Status';
import { BreakDownPDF, CompanyDetailedPDF, ExplorePDF, VerticalPDF } from './pages/data/components/PDFCreate';
import {CompanyDataEditor} from "./pages/admin/adminviews/CompanyDataEditor";


const theme = createTheme({
  palette: {
    /*
    primary: {
      light: '#eee',
      main: '#111',
      dark: '#222',
      contrastText: '#eee',
    },
    */
  },
});

function App() {

  return (
    <StatusProvider>
      <APIProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Router>
              { window.location.pathname !== '/demo' ? (
                  <Navbar />
                ) : null}
              <ProjectProviderNew>

                <Routes>

                  { /* App.SocialMarks.Com */ }

                  <Route path='/demo' element={<IFrameExplore />} />
                  <Route path='/cohorts' element={<IsNotGuest><Cohorts /></IsNotGuest>} />

                  { /*
                  <Route path='/auth/userhome' element={<UserHome />} />
                  <Route path='/auth/techsupport' element={<TechSupport />} />
                  <Route path='/auth/invite' element={<Invite />} />
                  */}

                  <Route path='/auth/account' element={<Account />} />

                  <Route path='/' element={<Login />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/logout' element={<Logout />} />
                  <Route path='/auth/register' element={<Register />} />
                  <Route path='/forgotpassword' element={<ForgotPassword />} />


                  { /* App.SocialMarks.Com */ }

                  { /* <Route path='/admin/login' element={<AdminLogin />} /> */ }
                  <Route path='/admin/home' element={<IsNotGuest><AdminHome /></IsNotGuest>} />
                  <Route path='/admin/company/:companyId' element={<CompanyDataEditor />} />
                  { /* <Route path='/admin/user' element={<UserAdmin />} />
                  <Route path='/admin/file/management' element={<FileManagement />} />
                  <Route path='/admin/file/processing' element={<FileProcessing />} />
                  <Route path='/admin/project' element={<AdminProjectHome />} />
                  <Route path='/admin/project/create' element={<AdminProjectCreate />} />
                  <Route path='/admin/project/active' element={<AdminProjectActive />} />
                  <Route path='/admin/data/upload' element={<DataUpload />} />
                  <Route path='/admin/data/entry' element={<DataEntry />} />
                  */}


                  { /* explore data socialmarks.Com */ }
                  <Route path='/data/explore' element={<ExplorePDF />} />

                  <Route path='/data/vertical' element={<IsNotGuest><VerticalPDF /></IsNotGuest>} /> { /* PDF VERSION */ }
                  <Route path='/data/exploratory' element={<IsNotGuest><BreakDownPDF /></IsNotGuest>} /> { /* PDF VERSION */ }
                  <Route path='/data/company/:companyId' element={<CompanyDetailedPDF />} /> { /* PDF VERSION */ }

                  { /* <Route path='/data/exploratory/pdf' element={<IsNotGuest><BreakDownPDF /></IsNotGuest>} /> */}
                  { /* <Route path='/data/company/pdf/:companyId' element={<IsNotGuest><CompanyDetailedPDF /></IsNotGuest>} /> */ }

                  <Route path='/data/forecasting' element={<IsNotGuest><Forecasting /></IsNotGuest>} />
                </Routes>

              </ProjectProviderNew>
              { window.location.pathname !== '/demo' ? (
              <Footer />
              ) : null }
            </Router>
          </ThemeProvider>
        </AuthProvider>
      </APIProvider>
    </StatusProvider>
  );
}

export default App;
