import { Container } from '@mui/material'
import * as React from 'react'
import { RegisterForm } from '../../components/cognitoauth/views/RegisterForm'

export const Register = (props) => {


    return (
        <Container>
            <RegisterForm />
        </Container>
    )
}