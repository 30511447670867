import React, { useState, useEffect, useContext } from 'react'
//import { websiteAuth } from '../config/config'

import * as cognito from '../libs/cognito'
import { APIContext } from '../../api/Api'
// Auth Status is as follows
// 0    Loading | Idle
// -1   Logged Out
// 1    Logged In
// 2? Maybe for admin status later

const defaultState = {
  sessionInfo: {},
  authStatus: 0,
  superAdmin: false,
  isGuest: true,
  isDemo: false
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }) => {
    const { authStatus } = useContext(AuthContext)
    return <>{authStatus === 1 ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }) => {
    const { authStatus } = useContext(AuthContext)
    return <>{authStatus === -1 ? children : null}</>
}

export const AuthIsSuper = ({ children }) => {
    const { superAdmin } = useContext(AuthContext)
    //console.log(`AuthIsSuper ${superAdmin === true}`)
    return <>{superAdmin === true ? children : null}</>
}

export const IsNotGuest = ({children}) => {
    const { isGuest } = useContext(AuthContext)
    return <>{isGuest === false ? children : null}</>
}

export const IsGuest = ({children}) => {
    const { isGuest } = useContext(AuthContext)
    return <>{isGuest === true ? children : null}</>
}


export const IsNotDemo = ({children}) => {
    const { isDemo } = useContext(AuthContext)
    return <>{isDemo === false ? children : null}</>
}

export const IsDemo = ({children}) => {
    const { IsDemo } = useContext(AuthContext)
    return <>{IsDemo === true ? children : null}</>
}


const AuthProvider = ({ children }) => {
    const [superAdmin, setSuperAdmin] = React.useState(false)
    const [authStatus, setAuthStatus] = useState(-1)
    const [sessionInfo, setSessionInfo] = useState({})
    const [attrInfo, setAttrInfo] = useState([])
    const [isGuest, setIsGuest] = useState(true)
    const [isDemo, setIsDemo] = useState(true)
    const [subscription, setSubscription ] = useState("")

    const apic = React.useRef(React.useContext(APIContext))

    // onload get session
    React.useEffect(() => {
        getSession()
    }, [])


    useEffect(() => {
        console.log(`Subscription ${subscription}`)
    }, [subscription])

    useEffect(() => {
        console.log(`Guest ${isGuest}`)
    }, [isGuest])

    React.useEffect(() => {
        if ((attrInfo === undefined) || (attrInfo.length === 0)) return
        // Check super admin
        setSuperAdmin(false)
        for(let i = 0;i < attrInfo.length; i++) {
            if((attrInfo[i].Name === "custom:superadmin") && (attrInfo[i].Value === "true")) {
                console.log("[ admin true ]")
                setSuperAdmin(true)
                apic.current.changeAdminS(true)
            }
        }
    }, [attrInfo])


    React.useEffect(() => {
        if ((attrInfo === undefined) || (attrInfo.length === 0)) return
        // Check super admin
        setIsDemo(false)
        for(let i = 0;i < attrInfo.length; i++) {
            if((attrInfo[i].Name === "custom:subscription") && (attrInfo[i].Value === "Demo")) {
                setIsDemo(true)
            }
        }
    }, [attrInfo])

    React.useEffect(() => {
        //console.log(superAdmin)
    }, [superAdmin])


    if (authStatus === 0) {
            return null
    }

    async function signInWithEmail(username, password) {
        try {
            await cognito.signInWithEmail(username, password)
            await getSession()
            setAuthStatus(1)
        } catch (err) {
            setAuthStatus(-1)
            throw err
        }
    }

    async function signUpWithEmail(username, email, password) {
        try {
            await cognito.signUpUserWithEmail(username, email, password)
        } catch (err) {
            throw err
        }
    }

    async function signOut() {
        try {
            cognito.signOut()
            window.localStorage.setItem('accessToken', null)
            window.localStorage.setItem('refreshToken', null)
            window.localStorage.setItem('idToken', null)
            apic.current.changeAuthS(false)
            apic.current.changeAdminS(false)
            apic.current.changeTokenS(null)
            apic.current.changeAccessS(null)
            setAuthStatus(-1)
        } catch (err) {
            console.log(err)
        }
    }

    async function verifyCode(username, code) {
        try {
            await cognito.verifyCode(username, code)
        } catch (err) {
            throw err
        }
    }

    async function getSession() {
        try {
            const session = await cognito.getSession()
            setSessionInfo({
                idToken: session.idToken,
                accessToken: session.accessToken.jwtToken,
                refreshToken: session.refreshToken.token,
            })
            window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
            window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
            window.localStorage.setItem('idToken', `${session.idToken.jwtToken}`)

            //console.log("[session]")
            //console.log(session)

            //await setAttribute({ Name: 'website', Value: websiteAuth })


            const attr = await getAttributes()
            console.log(attr)
            //apic.current.SetAttributes(attr)

            console.log("[ setting auth state ]")
            setAttrInfo(attr)
            setAuthStatus(1)

            // Check & Set superadmin
            apic.current.changeTokenS(`${session.idToken.jwtToken}`)
            apic.current.changeAccessS(`${session.accessToken.jwtToken}`)
            apic.current.changeAuthS(true)
            console.log("[ tokens set ]")


            console.log("[ getting user attrs ]")
            const filterV = attr.filter(i => i.Name === "custom:subscription")
            if (filterV !== undefined && filterV.length === 1) {
                //console.log(`[ subscription ] ${filterV[0].Value}`)
                if (filterV[0].Value !== undefined) {
                    setSubscription(filterV[0].Value)
                }
            }

            const adminV = attr.filter(i => i.Name === "custom:superadmin")
            
            if ((filterV !== undefined && filterV.length === 1 && filterV !== null) || // Check license value is exists
                    (adminV !== undefined && adminV.length === 1 && adminV[0].Value === "true")) {  // else if superadmin change to false for guest
                //const v = filterV[0].Value
                //console.log(`License: ${v}`)
                console.log('yes')
                setIsGuest(false)
            } else {
                setIsGuest(true)    // everyone a guest if above check doesnt work
            }

            return session
        } catch (err) {
            setAuthStatus(-1)
            apic.current.changeAuthS(false)
            apic.current.changeAdminS(false)
        //throw err
        }
    }

    async function getAttributes() {
        try {
            const attr = await cognito.getAttributes()
            return attr
        } catch (err) {
            throw err
        }
    }

    async function setAttribute(attr) {
        try {
            const res = await cognito.setAttribute(attr)
            return res
        } catch (err) {
            throw err
        }
    }

    async function sendCode(username) {
        try {
            await cognito.sendCode(username)
        } catch (err) {
            throw err
        }
    }

    async function forgotPassword(username, code, password) {
        try {
            await cognito.forgotPassword(username, code, password)
        } catch (err) {
            throw err
        }
    }

    async function changePassword(oldPassword, newPassword) {
        try {
            await cognito.changePassword(oldPassword, newPassword)
        } catch (err) {
            throw err
        }
    }

    const state = {
        authStatus,
        sessionInfo,
        attrInfo,
        signUpWithEmail,
        signInWithEmail,
        signOut,
        verifyCode,
        getSession,
        sendCode,
        forgotPassword,
        changePassword,
        getAttributes,
        setAttribute,
        superAdmin,
        isGuest,
    }

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
